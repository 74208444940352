import { DatePicker } from "antd";
import { ChevronUp, Info } from "feather-icons-react/build/IconComponents";
import ArrowLeft from "feather-icons-react/build/IconComponents/ArrowLeft";
import React, { useEffect, useState } from "react";
import { PlusCircle } from "react-feather";
import { Link } from "react-router-dom";
import Select from "react-select";
import { all_routes } from "../../Router/all_routes";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { setToogleHeader } from "../../core/redux/action";
import { createEmployee } from "../../service/ApiServices";

const AddEmployee = () => {
  const route = all_routes;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);

  // fetch shift details and set it to options
  const [shiftOptions, setShiftOptions] = useState([]);

  useEffect(() => {
    fetch("http://localhost:8000/api/shifts")
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        const processedShiftOptions = data.data.shifts.map(item => ({
          value: item._id,
          label: item.shiftInfo.shiftName
        }));
        setShiftOptions(processedShiftOptions);
      })
      .catch(error => console.error('Error fetching shifts:', error));
  }, []);

  // fetch designation details and set it to options
  const [designationOptions, setDesignationOptions] = useState([]);

  useEffect(() => {
    fetch("http://localhost:8000/api/designations")
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        const processedDesignationOptions = data.data.designations.map(item => ({
          value: item._id,
          label: item.designationName 
        }));
        setDesignationOptions(processedDesignationOptions);
      })
      .catch(error => console.error('Error fetching designations:', error));
  }, []);

  // fetch department details and set it to options
  const [departmentOptions, setDepartmentOptions] = useState([]);

  useEffect(() => {
    fetch("http://localhost:8000/api/departments")
      .then(response => response.json())
      .then(data => {
        console.log(data);
        const processedDepartmentOptions = data.data.departments.map(item => ({
          value: item._id,
          label: item.departmentName 
        }));
        setDepartmentOptions(processedDepartmentOptions);
      })
      .catch(error => console.error('Error fetching department:', error));
  }, []);

  // fetch country name and set it to country option
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        // console.log(data);
        const processedCountryOptions = data.map(country => ({
          value: country.name.common,
          label: country.name.common
        }));
        setCountries(processedCountryOptions);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  // fetch nationalities and set to country option
  const [nationalitiesOptions, setNationality] = useState([]);

  useEffect(() => {
    const fetchNationalities = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        const nationalities = data.reduce((acc, country) => {
          if (country.demonyms && country.demonyms.eng) {
            acc.push(country.demonyms.eng.m);
            acc.push(country.demonyms.eng.f);
          }
          return acc;
        }, []);
        const uniqueNationalities = [...new Set(nationalities)];
        const processedNationalityOptions = uniqueNationalities.map(nationality => ({
          value: nationality,
          label: nationality
        }));
        setNationality(processedNationalityOptions);
      } catch (error) {
        console.error('Error fetching nationalities:', error);
      }
    };

    fetchNationalities();
  }, []);


  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    empCode: '',
    dateOfBirth: new Date(),
    gender: '',
    nationality: '',
    joiningDate: new Date(),
    shiftId: '',
    departmentId: '',
    designationId: '',
    bloodGroup: '',
    emergencyNo1: '',
    emergencyNo2: '',
    address: '',
    country: '',
    state: '',
    city: '',
    zipcode: '',
    password: '',
    profilePicture: null,
  });

  const [confirmPassword, setConfPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const handlePasswordChange = (event) => {
    setFormData({ ...formData, password: event.target.value });
  };

  const handleConfirmPasswordChange = (event) => {
    setConfPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (formData.password === confirmPassword) {
      setFormData({ ...formData, password: confirmPassword });
      console.log("Passwords matched");
      setPasswordsMatch(true);
    } else {
      console.log("Passwords don't match");
      return setPasswordsMatch(false);
    }

    
    console.log("form Data : " , formData);

    const formDataToSend = new FormData();
    // Object.entries(formData).forEach(([key, value]) => {
    //   if (key === 'profilePicture') {
    //     formDataToSend.append(key, value);
    //   } else if (key === 'dateOfBirth' || key === 'joiningDate') {
    //     formDataToSend.append(key, value.toISOString());
    //   } else {
    //     formDataToSend.append(key, value);
    //   }
    // });
    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'profilePicture') {
        if (value !== null) {
          formDataToSend.append(key, value);
        }
      } else if (key === 'dateOfBirth' || key === 'joiningDate') {
        formDataToSend.append(key, value.toISOString());
      } else {
        formDataToSend.append(key, value);
      }
    });
    console.log('form data to send',formDataToSend);


    try {
      const response = await createEmployee(formDataToSend);
      console.log('Employee created:', response);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        empCode: '',
        dateOfBirth: new Date(),
        gender: '',
        nationality: '',
        joiningDate: new Date(),
        shiftId: '',
        departmentId: '',
        designationId: '',
        bloodGroup: '',
        emergencyNo1: '',
        emergencyNo2: '',
        address: '',
        country: '',
        state: '',
        city: '',
        zipcode: '',
        password: '',
        profilePicture: null,
      });
    } catch (error) {
      console.error('Error creating employee:', error);
    }
    
  };
  

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    let newValue = value;
  
    if (type === 'file') {
      newValue = event.target.files[0];
    } else if (type === 'date') {
      const selectedDate = new Date(value);
      newValue = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      );
    }
  
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const handleToggleConfirmPassword = () => {
    setConfirmPassword((prevShowPassword) => !prevShowPassword);
  };
  const gender = [
    { value: "Choose", label: "Choose" },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  const nationality = [
    { value: "Choose", label: "Choose" },
    // { value: "United Kingdom", label: "United Kingdom" },
    // { value: "India", label: "India" },
    ...nationalitiesOptions
  ];
  const shifts = [
    { value: "Choose", label: "Choose" },
    // { value: "Regular", label: "Regular" },
    ...shiftOptions
  ];
  const departments = [
    { value: "Choose", label: "Choose" },
    // { value: "UI/UX", label: "UI/UX" },
    // { value: "Support", label: "Support" },
    // { value: "HR", label: "HR" },
    // { value: "Engineering", label: "Engineering" },
    ...departmentOptions
  ];
  const designation = [
    { value: "Choose", label: "Choose" },
    // { value: "Designer", label: "Designer" },
    // { value: "Developer", label: "Developer" },
    // { value: "Tester", label: "Tester" },
    ...designationOptions
  ];
  const bloodgroup = [
    // { value: "Select", label: "Select" },
    { value: "A+", label: "A+" },
    { value: "A-", label: "A-" },
    { value: "B+", label: "B-" },
    { value: "O+", label: "O-" },
    { value: "O+", label: "O-" },
    { value: "AB+", label: "AB-" },
    { value: "AB+", label: "AB-" },
  ];
  const country = [
    { value: "Choose", label: "Choose" },
    // { value: "United Kingdom", label: "United Kingdom" },
    // { value: "USA", label: "USA" },
    ...countries
  ];

  const handleSelectChange = (selectedOption, name) => {
    setFormData({
      ...formData,
      [name]: selectedOption.value,
    });
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>New Employee</h4>
                <h6>Create new Employee</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <div className="page-btn">
                  <Link to={route.employeegrid} className="btn btn-secondary">
                    <ArrowLeft className="me-2" />
                    Back to Employee List
                  </Link>
                </div>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          {/* /product list */}
          <form onSubmit={handleSubmit}>
            <div className="card">
              <div className="card-body">
                <div className="new-employee-field">
                  <div className="card-title-head">
                    <h6>
                      <span>
                        <Info className="feather-edit" />
                      </span>
                      Employee Information
                    </h6>
                  </div>
                  <div className="profile-pic-upload">
                    <div className="profile-pic">
                      <span>
                        <PlusCircle className="plus-down-add" />
                        Profile Photo
                      </span>
                    </div>
                    <div className="input-blocks mb-0">
                      <div className="image-upload mb-0">
                        <input 
                          type="file"
                          name="profilePicture" 
                          onChange={handleChange} 
                          accept="image/*" 
                        />
                        <div className="image-uploads">
                          <h4>Change Image</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">First Name</label>
                        <input 
                          type="text" 
                          className="form-control"
                          name="firstName" 
                          value={formData.firstName} 
                          onChange={(event) => handleChange(event, 'firstName')}  
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Last Name</label>
                        <input 
                          type="text" 
                          className="form-control"
                          name="lastName" 
                          value={formData.lastName} 
                          onChange={(event) => handleChange(event, 'lastName')}  
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input 
                          type="email" 
                          className="form-control"
                          name="email" 
                          value={formData.email} 
                          onChange={handleChange}  
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Contact Number</label>
                        <input 
                          type="text" 
                          className="form-control"
                          name="contactNumber" 
                          value={formData.contactNumber} 
                          onChange={handleChange}  
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Emp Code</label>
                        <input 
                          type="text" 
                          className="form-control"
                          name="empCode" 
                          value={formData.empCode} 
                          onChange={handleChange}  
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-blocks">
                        <label>Date of Birth</label>
                        <div className="input-groupicon calender-input">
                          <DatePicker
                            selected={formData.dateOfBirth}
                            onChange={(date) => setFormData({ ...formData, dateOfBirth: date })}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder="Choose Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Gender</label>

                        <Select
                          name="gender"
                          value={gender.find(option => option.value === formData.gender)} 
                          onChange={(selectedOption) => handleSelectChange(selectedOption, 'gender')}
                          className="select"
                          options={gender}
                          placeholder="Choose"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Nationality</label>

                        <Select
                          name="nationality"
                          value={nationality.find(option => option.value === formData.nationality)} 
                          onChange={(selectedOption) => handleSelectChange(selectedOption, 'nationality')}
                          className="select"
                          options={nationality}
                          placeholder="Choose"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-blocks">
                        <label>Joining Date</label>
                        <div className="input-groupicon calender-input">
                          <DatePicker
                            selected={formData.joiningDate}
                            onChange={(date) => setFormData({ ...formData, joiningDate: date })}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder="Choose Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <div className="add-newplus">
                          <label className="form-label">Shift</label>
                          <Link to="#">
                            <span>
                              <PlusCircle className="plus-down-add" />
                              Add new
                            </span>
                          </Link>
                        </div>

                        <Select
                          className="select"
                          options={shifts}
                          placeholder="Choose"
                          onChange={(selectedOption) => handleSelectChange(selectedOption, 'shiftId')}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Department</label>

                        <Select
                          className="select"
                          options={departments}
                          placeholder="Choose"
                          onChange={(selectedOption) => handleSelectChange(selectedOption, 'departmentId')}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Designation</label>

                        <Select
                          className="select"
                          options={designation}
                          placeholder="Choose"
                          onChange={(selectedOption) => handleSelectChange(selectedOption, 'designationId')}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Blood Group</label>
                        <Select
                          name="bloodGroup"
                          value={bloodgroup.find(option => option.value === formData.bloodGroup)} 
                          onChange={(selectedOption) => handleSelectChange(selectedOption, 'bloodGroup')}
                          className="select"
                          options={bloodgroup}
                          placeholder="Choose"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="other-info">
                    <div className="card-title-head">
                      <h6>
                        <span>
                          <Info className="feather-edit" />
                        </span>
                        Other Information
                      </h6>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Emergency No 1</label>
                          <input 
                            type="text"   
                            className="form-control" 
                            name="emergencyNo1" 
                            value={formData.emergencyNo1} 
                            onChange={handleChange} 
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Emergency No 2</label>
                          <input 
                            type="text"   
                            className="form-control" 
                            name="emergencyNo2" 
                            value={formData.emergencyNo2} 
                            onChange={handleChange} 
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6"></div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Address</label>
                          <input 
                            type="text"   
                            className="form-control" 
                            name="address" 
                            value={formData.address} 
                            onChange={handleChange} 
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Country</label>

                          <Select
                            name="country"
                            value={country.find(option => option.value === formData.country)} 
                            onChange={(selectedOption) => handleSelectChange(selectedOption, 'country')}
                            className="select"
                            options={country}
                            placeholder="Select"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">State</label>
                          <input 
                            type="text"   
                            className="form-control" 
                            name="state" 
                            value={formData.state} 
                            onChange={handleChange} 
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">City</label>
                          <input 
                            type="text"   
                            className="form-control" 
                            name="city" 
                            value={formData.city} 
                            onChange={handleChange} 
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Zipcode</label>
                          <input 
                            type="text"   
                            className="form-control" 
                            name="zipcode" 
                            value={formData.zipcode} 
                            onChange={handleChange} 
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pass-info">
                    <div className="card-title-head">
                      <h6>
                        <span>
                          <Info />
                        </span>
                        Password
                      </h6>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="input-blocks mb-md-0 mb-sm-3">
                          <label>Password</label>
                          <div className="pass-group">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="pass-input"
                              placeholder="Enter your password"
                              value={formData.password}
                              onChange={handlePasswordChange}
                            />
                            <span
                              className={`fas toggle-password ${
                                showPassword ? "fa-eye" : "fa-eye-slash"
                              }`}
                              onClick={handleTogglePassword}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="input-blocks mb-0">
                          <label>Confirm Password</label>
                          <div className="pass-group">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              className="pass-input"
                              placeholder="Enter your password"
                              value={confirmPassword}
                              onChange={handleConfirmPasswordChange}
                            />
                            <span
                              className={`fas toggle-password ${
                                showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                              }`}
                              onClick={handleToggleConfirmPassword}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    {!passwordsMatch && <p style={{ color: 'red' }}>Passwords do not match!</p>}
                  </div>
                </div>
              </div>
            </div>
            {/* /product list */}
            <div className="text-end mb-3">
              <button type="button" className="btn btn-cancel me-2">
                Cancel
              </button>
              <button to="#" className="btn btn-submit">
                Save Product
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
