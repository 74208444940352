import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import { createExpense, getExpense, updateExpense } from "../../../service/ApiServices";
import  Select  from "react-select";
import { format } from 'date-fns';
import { BASE_URL } from "../../../environment";

const ExpenseModal = ({onExpenseAdded, expenseId}) => {

  const [expenseDate, setExpenseDate] = useState(null);
  const [expenseCategories, setExpenseCategories] = useState([]);

  const handleDateChangeModal = (date) => {
    setExpenseDate(date);

    setFormData((prevData) => ({
      ...prevData,
      expenseDate: date,
  }));
  };

  const [formData, setFormData] = useState({
    expenseCategory: "",
    expenseDate: "",
    expenseAmount: 0,
    expenseState: true,
    expenseFor: "",
    expenseDescription: "",

  });

  // console.log(CustomerModal)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const setSelectedExpense = async (expenseId) => {
    try {
      const response = await getExpense(expenseId);
      if (response.data) {
        const { expenseCategory, expenseDate, expenseAmount, expenseState, expenseFor, expenseDescription } = response.data.data.expense;
        setFormData({
          ...formData,
          expenseCategory,
          expenseDate,
          expenseAmount,
          expenseState,
          expenseFor,
          expenseDescription,
        });  
      } else {
        console.error("Failed to fetch Expense data");
      }
    } catch (error) {
      console.error("Error fetching Expense data:", error);
    }
  };

  useEffect(() => {
    if (expenseId) {
      setSelectedExpense(expenseId);
    }
  }, [expenseId]);

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedDate = expenseDate ? format(expenseDate, "dd/MM/yyyy") : "";

      const expenseData = {
        ...formData,
        expenseDate: formattedDate,
        expenseAmount: parseFloat(formData.expenseAmount), // Ensure it's a number
      };
      console.log("iiiiiiiiiiiiiiiiiiiiiiii")
      console.log(expenseData)
      const response = await updateExpense(expenseId, expenseData); 
      onExpenseAdded();
      console.log("Expense updated successfully:", response);
      setFormData({
        // Clear form data if needed
        expenseCategory: "",
        expenseDate: "",
        expenseAmount: 0,
        expenseState: true,
        expenseFor: "",
        expenseDescription: "",
      });
    } catch (error) {
      console.error("Error updating Expense:", error);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedDate = expenseDate ? format(expenseDate, "dd/MM/yyyy") : "";

      const expenseData = {
        ...formData,
        expenseDate: formattedDate,
        expenseAmount: parseFloat(formData.expenseAmount), // Ensure it's a number
      };

      console.log("oooooooooooooooooooo")
      console.log(expenseData)
        const response = await createExpense(expenseData);
        console.log(response)
        onExpenseAdded();
        console.log("Expense added successfully:", response.data);
      setFormData({
        expenseCategory: "",
        expenseDate: "",
        expenseAmount: 0,
        expenseState: true,
        expenseFor: "",
        expenseDescription: "",
      });
    } catch (error) {
      console.error("Error adding supplier:", error);
    }
  };

  useEffect(() => {
    fetchExpenseCategories();
  }, []);

  const fetchExpenseCategories = async () => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/expenseCategories`);
      if (!response.ok) {
        throw new Error("Failed to fetch Expense Categories");
      }
      const data = await response.json();
      console.log(data)
      const options = data.data.expenseCategory.map((category) => ({
        label: category.expenseCategoryName,
        value: category._id,
      }));
      
      setExpenseCategories(options);
      console.log("---------------")
      console.log(expenseCategories)
    } catch (error) {
      console.error("Error fetching Expense Categories:", error);
    }
  };

  return (
    <div>
      {/* Add Expense */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Expense</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label">Expense Category</label>
                          <Select
                            options={expenseCategories}
                            placeholder="Choose Category"
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              expenseCategory: selectedOption.value,
                            }))}
                            required
                            // placeholder="Choose"
                          />
                          {/* <Select
                          className="select select-height"
                          options={optionsOne}
                          defaultValue={optionsOne[0]}
                        /> */}
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="input-blocks date-group">
                            <i data-feather="calendar" className="info-img" />
                            <div className="input-groupicon">
                            <DatePicker
                                name="expenseDate"
                                selected={expenseDate}
                                onChange={handleDateChangeModal}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Choose Date"
                                className="datetimepicker"
                                required
                            />
                            </div>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-8">
                            <div className="mb-3">
                            <label className="form-label">Amount</label>
                            <input
                                type="text"
                                className="form-control"
                                name="expenseAmount"
                                value={formData.expenseAmount}
                                onChange={handleChange}
                                placeholder="$"
                                required
                            />
                            </div>
                        </div>
                        <div className="col-lg-4">
                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <span className="form-label">Status</span>
                          <input
                            type="checkbox"
                            id="user2"
                            className="check"
                            name="expenseState"
                            checked={formData.expenseState} // Bind to `expenseState`
                            onChange={(e) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                expenseState: e.target.checked, // Update state based on checkbox value
                              }));
                            }}
                            required
                          />
                          <label htmlFor="user2" className="checktoggle" /> 
                        </div>
                        </div>
                        </div>
                        <div className="col-lg-12">
                        <div className="mb-3">
                            <label className="form-label">Expense For</label>
                            <input
                                type="text"
                                className="form-control"
                                name="expenseFor"
                                value={formData.expenseFor}
                                onChange={handleChange}
                                required
                            />
                            <span className="unlimited-text">0 for Unlimited</span>
                        </div>
                        </div>                                    
                        {/* Editor */}
                        <div className="col-md-12">
                        <div className="edit-add card">
                            <div className="edit-add">
                            <label className="form-label">Description</label>
                            </div>
                            <div className="card-body-list input-blocks mb-0">
                            <textarea
                                className="form-control mb-1"
                                name="expenseDescription"
                                value={formData.expenseDescription}
                                onChange={handleChange}
                                required
                            />
                            </div>
                            <p>Maximum 600 Characters</p>
                        </div>
                        </div>
                        {/* /Editor */}
                    </div>
                    <div className="modal-footer-btn">
                    <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button type="submit"
                            className="btn btn-submit"
                            data-bs-dismiss="modal">
                            Submit
                    </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Expense */}

      {/* Edit Expense */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Expense</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleUpdateSubmit}>
                    <div className="row">
                        <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label">Expense Category</label>
                          <Select
                            options={expenseCategories}
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              expenseCategory: selectedOption.value,
                            }))}
                            placeholder="Choose"
                            required
                          />
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="input-blocks date-group">
                            <i data-feather="calendar" className="info-img" />
                            <div className="input-groupicon">
                            <DatePicker
                                name="expenseDate"
                                selected={expenseDate}
                                onChange={handleDateChangeModal}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Choose Date"
                                className="datetimepicker"
                                required
                            />
                            </div>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-8">
                            <div className="mb-3">
                            <label className="form-label">Amount</label>
                            <input
                                type="text"
                                className="form-control"
                                name="expenseAmount"
                                value={formData.expenseAmount}
                                onChange={handleChange}
                                placeholder="$"
                                required
                            />
                            </div>
                        </div>
                        <div className="col-lg-4">
                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <span className="form-label">Status</span>
                          <input
                            type="checkbox"
                            id="user2"
                            className="check"
                            name="expenseState"
                            checked={formData.expenseState} // Bind to `expenseState`
                            onChange={(e) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                expenseState: e.target.checked, // Update state based on checkbox value
                              }));
                            }}
                            required
                          />
                          <label htmlFor="user2" className="checktoggle" /> 
                        </div>
                        </div>
                        </div>
                        <div className="col-lg-12">
                        <div className="mb-3">
                            <label className="form-label">Expense For</label>
                            <input
                                type="text"
                                className="form-control"
                                name="expenseFor"
                                value={formData.expenseFor}
                                onChange={handleChange}
                                required
                            />
                            <span className="unlimited-text">0 for Unlimited</span>
                        </div>
                        </div>                                    
                        {/* Editor */}
                        <div className="col-md-12">
                        <div className="edit-add card">
                            <div className="edit-add">
                            <label className="form-label">Description</label>
                            </div>
                            <div className="card-body-list input-blocks mb-0">
                            <textarea
                                className="form-control mb-1"
                                name="expenseDescription"
                                value={formData.expenseDescription}
                                onChange={handleChange}
                                required
                            />
                            </div>
                            <p>Maximum 600 Characters</p>
                        </div>
                        </div>
                        {/* /Editor */}
                    </div>
                    <div className="modal-footer-btn">
                    <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button type="submit"
                            className="btn btn-submit"
                            data-bs-dismiss="modal">
                            Submit
                    </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Expense */}
    </div>
  );
};

ExpenseModal.propTypes = {
    onExpenseAdded: PropTypes.func.isRequired,
    expenseId: PropTypes.string,
  };

export default ExpenseModal;
