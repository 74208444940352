import React, { useEffect, useState } from 'react';
import { Modal, Popconfirm, Spin, Table } from 'antd';
import { PlusCircle, Sliders } from 'react-feather';
import { Link } from 'react-router-dom';
import { getAllDesignations,createDesignationMethod, deleteDesignation } from '../../service/ApiServices';
import Swal from 'sweetalert2';
import { API_BASE_URL } from "../../../src/environment";
const Designation = () => {
  const [data, setData] = useState([]);
  const [success, setSuccess] = useState(false)
  const [isEdit,setIsEdit] = useState(false)
  const [isDelete,setIsDelete] = useState(false)
  const [formData, setFormData] = useState({
    designationName: ""
  })
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const storedUserData = localStorage.getItem("user");
  const userData = JSON.parse(storedUserData);
  const id = userData?.data?.uid; //
  const [userRightssub, setUserRightssub] = useState([]);

  useEffect(() => {
    const fetchUserRightssub = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/userright/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user rights");
        }
        const data = await response.json();
        console.log("Fetched User Sub Data:", data.data);
        setUserRightssub(data.data || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching data:", error);
        setUserRightssub([]); // Set to empty array in case of error
      }
    };
  
    fetchUserRightssub(); // Fetch the data on component mount
  }, [id]);
  const hasJobRightWithAdd = userRightssub.some(right => right.right === 'Designations' && right.adds === 1);
  const hasJobRightWithedit = userRightssub.some(right => right.right === 'Designations' && right.updates === 1);
 // const hasJobRightWithview = userRightssub.some(right => right.right === 'Designations' && right.viewes === 1);  
  const hasJobRightWithedel = userRightssub.some(right => right.right === 'Designations' && right.deletes=== 1); 
 // const hasJobRightWithdown = userRightssub.some(right => right.right === 'Designations' && right.fdownload=== 1); 
  useEffect(() => {
    const handler = setTimeout(() => {
      const fetchData = async () => {
        getAllDesignations({ search, setLoading: setLoading2, setData });
      };
      fetchData();
    }, 600);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (success) {
      getAllDesignations({ search, setLoading: setLoading2, setData });
      showSuccess("Successfully saved Designation!")
      handleCancel()
      handleReset()
    }
    if (isDelete) {
      getAllDesignations({ search, setLoading: setLoading2, setData });
      showSuccess("Successfully deleted Department!")
    }
  }, [success,isDelete])

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEdit(false)
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'designationName',
      key: 'designationName',
      width:"700px"
    },
    {
      title: 'Action',
      key: 'action',
      render: (_,record) => (
        <td className="action-table-data">
            {hasJobRightWithedit && (
                 <Link className="me-2 p-2" to="#"
                 onClick={() => {
                     setFormData({
                       id:record._id,
                       designationName:record.designationName
                     })
                     setIsEdit(true)
                     setIsModalOpen(true);
                 }}
                 >
                   <i data-feather="download" className="feather-edit"></i>
                 </Link>
            )}
            {hasJobRightWithedel && (
                      <Popconfirm
                      title="Delete the Designation"
                      description="Are you sure to delete this Designation?"
                      onConfirm={() => {
                          deleteDesignation({
                             id:record._id,
                             setIsDelete
                          })
                      }}
                      onCancel={() => {
                        console.log("c")
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Link className="confirm-text p-2" to="#">
                        <i data-feather="trash-2"
                          className="feather-trash-2"
          
                        ></i>
                      </Link>
                    </Popconfirm>
            )}



         
   
        </td>
      ),
    },
  ];
  const handleSubmit = () => {
    if (formData.designationName === "" ) {
      alert("Please fill all fields")
      return
    }
    createDesignationMethod({
      data: formData,
      setLoading,
      setSuccess
    })
  }
  const handleReset = () => {
    setFormData({
      id:"",
      designationName:""
    })
  }

  const showSuccess = (str) => {
    return Swal.fire({
      title: "Good job!",
      text: str,
      icon: "success"
    });
  }
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Designations</h4>
              <h6>Manage your Designations</h6>
            </div>
          </div>
          <div className="page-btn">
          {hasJobRightWithAdd && (
                <a className="btn btn-added" onClick={() => setIsModalOpen(true)}>
                <PlusCircle className="me-2" />
                Add New Designation
              </a>
            )}
           
          </div>
        </div>

        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Link className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
                <div className="wordset">
                  <ul>
                    <li>
                      <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="pdf">
                        <img src="assets/img/icons/pdf.svg" alt="pdf" />
                      </a>
                    </li>
                    <li>
                      <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="excel">
                        <img src="assets/img/icons/excel.svg" alt="excel" />
                      </a>
                    </li>
                    <li>
                      <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="print">
                        <img src="assets/img/icons/printer.svg" alt="print" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="search-set">
                <a href="#" className="btn btn-filter" id="filter_search">
                  <Sliders className="me-2" />Filter
                </a>
              </div>
            </div>
            <div className="table-responsive">
              <Table loading={loading2} columns={columns} dataSource={data} rowKey="value" pagination={false} />
            </div>
          </div>
        </div>
      </div>

      <Modal
        zIndex={200000}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        closable={false}
      >
        <div className="modal-content">
          <div className="page-wrapper-new p-0">
            <div className="content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>{isEdit?"Edit":"Add"} Designation</h4>
                  <p className="mb-0">Fill in the required fields to create an Designation</p>
                </div>
              </div>
              <div className="modal-body custom-modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">Designation Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={formData.designationName}
                          onChange={(e) => setFormData({ ...formData, designationName: e.target.value })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="" style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}>
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                      <button type='button' className="btn btn-submit ml-0" onClick={handleSubmit} disabled={loading}>
                        {loading ? <Spin size='small' /> : "Save"}
                      </button>
                    </div>

                    <button
                      type="button"
                      className="btn btn-cancel"
                      onClick={() => {
                        handleCancel();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Designation;

