import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { getHolidayById, editHoliday } from '../../../service/holidayService';
import propTypes from 'prop-types';
import Swal from 'sweetalert2';

const EditHolidays = ({ onHolidayAdded, holidayId }) => {
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState(true);
  const [duration, setDuration] = useState(0);
  // const [error, setError] = useState('');

  // setDuration
 
  useEffect(() => {
    if (holidayId) {
      setSelectedHoliday(holidayId);
    }
  }, [holidayId]);

  const setSelectedHoliday = async (holidayId) => {
    try {
      const response = await getHolidayById(holidayId);
      if (response.data) {
        const holiday = response.data.holiday;
        setName(holiday.name);
        setStartDate(moment(holiday.startDate)) ;
        setEndDate(moment(holiday.endDate));
        setDuration(holiday.duration);
        setStatus(holiday.status);
      } else {
        console.error('Failed to fetch holiday data');
      }
    } catch (error) {
      console.error('Error fetching holiday data:', error);
    }
  };
  
  const showWarning = (str) =>{
    return Swal.fire({
      title: "Error",
      text: str,
      icon: "error",
    });
  }

  useEffect(() => {
    if (startDate && endDate) {
        if (endDate.isBefore(startDate, 'day')) {
            showWarning('End date cannot be earlier than start date.');
            setEndDate(null);
            setDuration(0);
        } else {
            const diff = endDate.diff(startDate, 'days') + 1;
            setDuration(diff);
            //setError(''); 
        }
    }
}, [startDate, endDate]);



  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.checked);
  };

  const handleSubmit = async () => {
    const holiday = {
      name,
      startDate,
      endDate,
      duration,
      status,
    };

    try {
      console.log('Updated Holiday: ', holiday);
      const response = await editHoliday(holidayId, holiday);
      onHolidayAdded();
      console.log('Holiday updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating holiday:', error);
    }
  };

  const handleReset = () => {
    
    setStartDate(null);
    setEndDate(null);
    setStatus(true);
    setDuration(0);
    //setError('');
};


  return (
    <div>
      <div className="modal fade" id="edit-department">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Holiday</h4>
                  </div>
                  {/* <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button> */}
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Edit Holiday</label>
                          <input
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Start Date</label>
                          <div className="input-groupicon calender-input">
                            <DatePicker
                              onChange={handleStartDateChange}
                              value={startDate}
                              format="DD-MM-YYYY"
                              className="filterdatepicker"
                              placeholder="Select Start Date"
                              getPopupContainer={(trigger) => trigger.parentNode}
                              style={{ width: "100%",
                                boxSizing:"border-box"
                             }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>End Date</label>
                          <div className="input-groupicon calender-input">
                            <DatePicker
                              onChange={handleEndDateChange}
                              value={endDate}
                              format="DD-MM-YYYY"
                              className="filterdatepicker"
                              placeholder="Select End Date"
                              getPopupContainer={(trigger) => trigger.parentNode}
                              style={{ width: "100%",
                                boxSizing:"border-box"
                             }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>No of Days</label>
                          <input
                            value={duration}
                            type="number"
                            className="form-control"
                            placeholder="01"
                            disabled
                          />
                        </div>
                      </div> 
                    
                   
{/* error if select befor date as end */}
                      <div className="mb-0">
                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <span className="status-label">Status</span>
                          <input
                            type="checkbox"
                            id="user3"
                            className="check"
                            checked={status}
                            onChange={handleStatusChange}
                          />
                          <label htmlFor="user3" className="checktoggle">
                            {" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                                        
                        }} className="mt-5" >                   
                    <button
                        type="button"
                        className="btn btn-cancel"
                        data-bs-dismiss="modal"
                     >
                       Close
                    </button>
                    <div>
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        className="btn btn-submit"
                        data-bs-dismiss="modal"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                    </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EditHolidays.propTypes = {
  onHolidayAdded: propTypes.func.isRequired,
  holidayId: propTypes.string.isRequired,
};

export default EditHolidays;
