
import axios from "axios";
export const adminAttendanceBulk = async (data) => {
   // export const adminAttendanceBulk = async (data,callback) => {
    //const adminId = JSON.parse(localStorage.getItem("user")).id;

    try {

        const res = await axios.post(`/adminAtendances/bulk-upload`, {
            csv: data,
            //user: adminId
        });
        
        if (res.status === 201) {
            console.log("Successfully uploaded bulk data");
            //callback();
        }
        else {
            console.error("Failed to upload bulk data", res);
            
        }

    } catch (error) {

            console.error("An error occurred during the bulk upload:", error);
    }
};



// export const adminAttendanceBulk = async ({ data }) => {
//     const adminId = JSON.parse(localStorage.getItem("user")).id;
//     const bulkInsertData = data.map(item => ({
//         user: item.user_id,    
//         employee: item.employee_name, 
//         shift: item.shift, 
//         timeRange: [item.clock_in, item.clock_out],
//         status: item.status === "true" || item.status === "1", 
//         date: item.date, 
//         workingHours: item.working_hours 
//     }));

//     try {
//         const response = await axios.post('/adminAttendances', {
//             csv: bulkInsertData, 
//             userId: adminId,
//         });

//         if (response.status === 201) {
//             console.log("Successfully uploaded bulk data");
//         } else {
//             console.error("Failed to upload bulk data", response);
//         }

//     } catch (error) {
//         console.error("Error uploading bulk data:", error);
//     }
// };


// import axios from "axios";

// export const adminAttendanceBulk = async ({ data }) => {
//     const adminId = JSON.parse(localStorage.getItem("user")).id;
//     const bulkInsertData = data.map(item => ({
//         user: item.user_id,    
//         employee: item.employee_name, 
//         shift: item.shift, 
//         timeRange: [item.clock_in, item.clock_out],
//         status: item.status === "true" || item.status === "1", 
//         date: item.date, 
//         workingHours: item.working_hours 
//     }));

//     try {
//         const response = await axios.post('/adminAttendances', {
//             csv: bulkInsertData, 
//             userId: adminId,
//         });

//         if (response.status === 201) {
//             console.log("Successfully uploaded bulk data");
//         } else {
//             console.error("Failed to upload bulk data", response);
//         }

//     } catch (error) {
//         console.error("Error uploading bulk data:", error);
//     }
// };



