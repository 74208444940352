import React, {  useEffect, useState } from 'react'
import Select from 'react-select'
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TimePicker } from 'antd';
import moment from 'moment/moment';
import PropTypes from "prop-types";
import { editShift, getShiftById } from '../../../service/ApiServices';


dayjs.extend(customParseFormat);

const EditShift = ({onShiftAdded, shiftId}) => {
   
    // set extracted times for ui
    const [fromTime, setFromTimeUI] = useState(moment('00:00', 'HH:mm'));
    const [toTime, setToTimeUI] = useState(moment('00:00', 'HH:mm'));
    
    
    const [shiftName, setShiftName] = useState('');
    const [from, setFromTime] = useState(null);
    const [to, setToTime] = useState(null);
    const [weekOff, setWeekOffOption] = useState(null);
    const [weekOffUI, setWeekOffOptionUI] = useState(null);
    // const [recurringShift, setRecurringShiftIsChecked] = useState(false);
    const [status, setStatus] = useState(false);

    const [weekdaysDefinition, setWeekdaysDefinition] = useState([
        { day: 'Monday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
        { day: 'Tuesday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
        { day: 'Wednesday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
        { day: 'Thursday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
        { day: 'Friday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
        { day: 'Saturday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
        { day: 'Sunday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } }
    ]);
    

   

    const handleDayCheckboxChange = (index) => {
        const updatedWeekdays = [...weekdaysDefinition];
        updatedWeekdays[index].checked = !updatedWeekdays[index].checked;
        setWeekdaysDefinition(updatedWeekdays);
    };

    

    const handleChangeFromTime = (time, timeString) => {
        setFromTime(timeString);
    };

    const handleChangeToTime = (time, timeString) => {
        setToTime(timeString);
    };

    const handleChange = (weekOff) => {
        setWeekOffOptionUI(weekOff);
        setWeekOffOption(weekOff.value);
    };

    

    const handleStatusCheckboxChange = (event) => {
        console.log("Checkbox checked");
        setStatus(event.target.checked);
    };

    
    const setSelectedShift = async (shiftId) => {
        try {
          const response = await getShiftById(shiftId);
          console.log(response);
          if (response.data) {
            const newWeekdaysDefinition = response.data.shift.shiftInfo.weekdaysDefinition;
            const fromTime = moment(response.data.shift.shiftInfo.from, 'HH:mm');
            const toTime = moment(response.data.shift.shiftInfo.to, 'HH:mm');
           
            
            //shiftInfo value set for UI
            setShiftName(response.data.shift.shiftInfo.shiftName);
            setFromTimeUI(fromTime); //UI for UI time update
            setToTimeUI(toTime);
            setFromTime(fromTime._i); // this one for orginal time update
            setToTime(toTime._i);
            const initialWeekOffValue = response.data.shift.shiftInfo.weekOff;
            const initialWeekOffOption = weekoff.find(option => option.value === initialWeekOffValue);
            setWeekOffOptionUI(initialWeekOffOption);// for Initially ui rendering
            setWeekOffOption(initialWeekOffOption.value);
            setWeekdaysDefinition(newWeekdaysDefinition);
            // setRecurringShiftIsChecked(response.data.shift.shiftInfo.recurringShift);
            setStatus(response.data.shift.shiftInfo.status);

            

            // setDescription(response.data.shift.breakTimings.description);
          } else {
            console.error("Failed to fetch shift data");
          }
        } catch (error) {
          console.error("Error fetching shift data:", error);
        }
    };

    useEffect(() => {
        if (shiftId) {
            setSelectedShift(shiftId);
        }
    }, [shiftId]);

    
// ************change handle submit**********
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        
        const shift = {
            shiftInfo: {
                shiftName,
                from,
                to,
                weekOff,
                status,
                weekdaysDefinition 
             }
            
        };
        
        try {
            console.log(shift);
            const response = await editShift(shiftId, shift);
            onShiftAdded();
            console.log("Shift added successfully:",response);
            
        } catch (error) {
            console.error('Error creating shifts:', error);
        }
        
    };
    

    const weekoff = [
        { value: 'Choose', label: 'Choose' },
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' },
        { value: 'Sunday', label: 'Sunday' },
    ];
    const resetForm = () => {
        setWeekOffOptionUI(null); 
        setWeekdaysDefinition([
            { day: 'Monday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
            { day: 'Tuesday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
            { day: 'Wednesday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
            { day: 'Thursday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
            { day: 'Friday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
            { day: 'Saturday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } },
            { day: 'Sunday', checked: false, checkboxes: { allWeeks: false, first: false, second: false, third: false, fourth: false, fifth: false } }
        ]); 
    };
    
    return (
        <div>
            {/* Edit Shift */}
            <div className="modal fade" id="edit-units">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Edit Shift</h4>
                                    </div>

                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form>
                                        
                                        <div className="tab-content" id="pills-tabContent">
                                            <div
                                                className="tab-pane fade show active"
                                                id="pills-edit-shift-info"
                                                role="tabpanel"
                                                aria-labelledby="pills-edit-shift-info-tab"
                                            >
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="input-blocks">
                                                            <label>Shift Name</label>
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={shiftName} 
                                                                onChange={(e) => setShiftName(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="input-blocks">
                                                            <label>From</label>
                                                            <div className="form-icon">
                                                            <TimePicker
                                                            className="input-group-text"
                                                            onChange={handleChangeFromTime} 
                                                            value={fromTime}
                                                            format="HH:mm"
                                                            style={{ width: "100%",
                                                                boxSizing:"border-box"
                                                             }}
                                                          />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="input-blocks">
                                                            <label>To</label>
                                                            <div className="form-icon">
                                                            <TimePicker
                                                            className="input-group-text"
                                                            onChange={handleChangeToTime} 
                                                            value={toTime}
                                                            format="HH:mm"
                                                            style={{ width: "100%",
                                                                boxSizing:"border-box"
                                                             }}
                                                          />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="input-blocks">
                                                            <label>Weekoff</label>
                                                           
                                                            <Select
                                                                className="select"
                                                                options={weekoff}
                                                                placeopholder="Newest"
                                                                // value={selectedOption}
                                                                value={weekOffUI}
                                                                onChange={handleChange}
                                                                getOptionLabel={(option) => option.label}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="modal-table-item">
                                                            <h4>Weekdays Defeniton</h4>
                                                            <div className="table-responsive no-pagination">
                                                                <table className="table  datanew">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Days</th>
                                                                            
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="status-toggle modal-status d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="MondayCheckbox1"
                                                                                        className="check"
                                                                                        checked={weekdaysDefinition[0].checked}
                                                                                        onChange={() => handleDayCheckboxChange(0)}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="MondayCheckbox1"
                                                                                        className="checktoggle"
                                                                                    />
                                                                                    <span className="status-label ms-2">
                                                                                        Monday
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="status-toggle modal-status d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="TuesdayCheckbox1"
                                                                                        className="check"
                                                                                        checked={weekdaysDefinition[1].checked}
                                                                                        onChange={() => handleDayCheckboxChange(1)}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="TuesdayCheckbox1"
                                                                                        className="checktoggle"
                                                                                    />
                                                                                    <span className="status-label ms-2">
                                                                                        Tuesday
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="status-toggle modal-status d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="WednesdayCheckbox1"
                                                                                        className="check"
                                                                                        checked={weekdaysDefinition[2].checked}
                                                                                        onChange={() => handleDayCheckboxChange(2)}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="WednesdayCheckbox1"
                                                                                        className="checktoggle"
                                                                                    />
                                                                                    <span className="status-label ms-2">
                                                                                        Wednesday
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="status-toggle modal-status d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="ThursdayCheckbox1"
                                                                                        className="check"
                                                                                        checked={weekdaysDefinition[3].checked}
                                                                                        onChange={() => handleDayCheckboxChange(3)}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="ThursdayCheckbox1"
                                                                                        className="checktoggle"
                                                                                    />
                                                                                    <span className="status-label ms-2">
                                                                                        Thursday
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="status-toggle modal-status d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="FridayCheckbox1"
                                                                                        className="check"
                                                                                        checked={weekdaysDefinition[4].checked}
                                                                                        onChange={() => handleDayCheckboxChange(4)}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="FridayCheckbox1"
                                                                                        className="checktoggle"
                                                                                    />
                                                                                    <span className="status-label ms-2">
                                                                                        Friday
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                           
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="status-toggle modal-status d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="SaturdayCheckbox1"
                                                                                        className="check"
                                                                                        checked={weekdaysDefinition[5].checked}
                                                                                        onChange={() => handleDayCheckboxChange(5)}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="SaturdayCheckbox1"
                                                                                        className="checktoggle"
                                                                                    />
                                                                                    <span className="status-label ms-2">
                                                                                        Saturday
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                           
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="status-toggle modal-status d-flex align-items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="SundayCheckbox1"
                                                                                        className="check"
                                                                                        checked={weekdaysDefinition[6].checked}
                                                                                        onChange={() => handleDayCheckboxChange(6)}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="SundayCheckbox1"
                                                                                        className="checktoggle"
                                                                                    />
                                                                                    <span className="status-label ms-2">
                                                                                        Sunday
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                           
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            
                                                            <div className="input-blocks m-0">
                                                                <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                                    <span className="status-label">Status</span>
                                                                    <input
                                                                        type="checkbox"
                                                                        id="user7"
                                                                        className="check"
                                                                        defaultChecked="true"
                                                                        checked={status}
                                                                        onChange={handleStatusCheckboxChange}
                                                                    />
                                                                    <label
                                                                        htmlFor="user7"
                                                                        className="checktoggle mb-0"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                        <div style={{
                                 display: "flex",
                                justifyContent: "space-between",
                                 alignItems: "center",

                                }} className="mt-4" >
                         <button
                                 type="button"
                                className="btn btn-cancel"
                                 data-bs-dismiss="modal"
                                 >
                         Close
                        </button>
                        <div>
                        <button
                               type="button"
                               className="btn btn-cancel me-2"
                               onClick={resetForm}
                              >
                          Reset
                        </button>
                        <button
                                 type="button"
                                className="btn btn-submit"
                                onClick={handleSubmit}
                                data-bs-dismiss="modal"
                                 >
                           Save
                        </button>
                       </div>
                     </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Shift */}
        </div>
    )
}
EditShift.propTypes = {
    onShiftAdded: PropTypes.func.isRequired,
    shiftId: PropTypes.string.isRequired,
};

export default EditShift