import axios from "axios";
import Swal from "sweetalert2";

const showWarning = (str) =>{
    return Swal.fire({
      title: "Error",
      text: str,
      icon: "error",
    });
  }
const success = (str) => {
    return Swal.fire({
        title: "Success",
        text: str,
        icon: "success",
    });
}
export const createDeduction = async (obj) => {
    try {
        const response = await axios.post(
            `/deduction`, obj
        );
        await fetchDeductioDetails();
        await success(response.data.message)

        return response.data;
    } catch (error) {
        throw new Error(
            error.response.data.message || "Failed to create"
        );
    }
}    


export const fetchDeductioDetails = async () => {
    try {
        const response = await axios.get(`/deduction`);
        return response.data;
    } catch (error) {
        console.error('Error fetching deduction details:', error);
        throw error;
    }
};


export const editDeduction = async (id, type) => {
    try {
      const response = await axios.put(`/deduction/${id}`, type);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Failed to edit deduction");
    }
  };


  export const deleteDeduction = async ({id}) => {
    try {
        console.log(id)
      await axios.delete(`/deduction/${id}`).then(() => {

      }).catch((err) => {
        if (err.response.status === 404) {
          showWarning(err.response.data.message);
          return;
        }
        showWarning("Error occurred while deleting deduction");
      });
    } catch (error) {
      showWarning("Error occurred while deleting deduction");
    }
  };

