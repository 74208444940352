import React, {useEffect, useState} from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { login } from "../../../service/ApiServices";
import {Button, Toast} from "react-bootstrap";

const Signin = () => {
  localStorage.clear();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  // const [name, setName] = useState(""); // State to store the user's name
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowToast(false);
    }, 6000);
    return () => clearTimeout(timeoutId);
  }, [showToast]);
  const navigate = useNavigate();
  // const [error, setError] = useState("");

  // hanlde user login
  const handleLogin = async (e) => {
    e.preventDefault();
  
    let errorMessage = "";

    console.log("Email",email)
    if(email === ""){
      errorMessage = "Email cannot be empty"
    }
    else if(password === ""){
      errorMessage = "Password cannot be empty"
    }
   else if (!email || !/\S+@\S+\.\S+/.test(email)) {
      errorMessage = "Invalid email format";
    } else if (!password) {
      errorMessage = "Password cannot be empty";
    }
  
    if (errorMessage !== "") {
      setErrorMsg(errorMessage)
      setShowToast(true);
      return;
    }
  
    try {
      const res = await login({
        email: email,
        password: password,
      });



    // Store user data in local storage
    localStorage.setItem("user", JSON.stringify(res.data));

    // console.log("responseLocal", res.data)

      navigate("/") // navigate to dashboard
      // console.log("response", res)
    } catch (error) {
      setShowToast(true)
    }
  };
  

  const route = all_routes;
  return (
    
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper bg-img">
          <div className="login-content">
            <form action="index">
              <div className="login-userset">
                <div className="login-logo logo-normal">
                  <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                </div>
                <Link to={route.dashboard} className="login-logo logo-white">
                  <ImageWithBasePath src="assets/img/logo-white.png" alt />
                </Link>
                <div className="login-userheading">
                  <h3>Sign In</h3>
                  <h4>
                    Access the The Suit panel using your email and passcode.
                  </h4>
                </div>
                <div className="form-login mb-3">
                  <label className="form-label">Email Address</label>
                  <div className="form-addons">
                    <input
                      type="email"
                      className="form- control"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                    />
                    <ImageWithBasePath
                      src="assets/img/icons/mail.svg"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="form-login mb-3">
                  <label className="form-label">Password</label>
                  <div className="pass-group">
                    <input
                      type="password"
                      className="pass-input form-control"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      required
                    />
                    <span className="fas toggle-password fa-eye-slash" />
                  </div>
                </div>
                <div className="form-login authentication-check">
                  <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                      <div className="custom-control custom-checkbox">
                        <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                          <input type="checkbox" className="form-control" />
                          <span className="checkmarks" />
                          Remember me
                        </label>
                      </div>
                      <div className="text-end">
                        <Link className="forgot-link" to={route.forgotPassword}>
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-login">
                  <button
                    type="button"
                    className="btn btn-login"
                    onClick={handleLogin}
                  >
                    Sign In
                  </button>
                </div>

                <div className="form-sociallink">
                  <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                    <p>Copyright © 2024 Eyerax Technologies. All rights reserved</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="toast-container position-fixed top-0 end-0 p-3">
        <Toast
            show={showToast}
            onClose={()=>setShowToast(false)}
            id="solidDangerToast"
            className="colored-toast bg-danger text-fixed-white"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
        >
          <Toast.Header closeButton className="bg-danger text-fixed-white">
            <strong className="me-auto">Error</strong>
            <Button
                variant="close"
                onClick={()=>setShowToast(false)}
                aria-label="Close"
            />
          </Toast.Header>
          <Toast.Body>
            {/* Add your toast content here */}
            {errorMsg}
          </Toast.Body>
        </Toast>
      </div>

    </div>
  );
};

export default Signin;
