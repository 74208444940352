import { DatePicker, Modal, Upload, Image, message, Spin, Popconfirm, Table, Checkbox, Button, Alert, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { Info, PlusCircle, Sliders } from 'react-feather';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { createEmployeeMethod, deleteEmployeeMethod, getAllAllowances, getAllDepartments, getAllDesignations, getAllEmployeesMethod, getAllShiftsMethod } from '../../service/ApiServices';
import moment from 'moment';
import Swal from 'sweetalert2';
import Select from "react-select";
import './style.css'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { AiFillFilePdf } from 'react-icons/ai';
import { FaFileExcel, FaFileWord } from 'react-icons/fa';
import { API_BASE_URL } from "../../../src/environment";
const { RangePicker } = DatePicker;
import dayjs from 'dayjs';
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const bloodgroup = [
  { value: "", label: "Choose" },
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B-" },
  { value: "O+", label: "O-" },
  { value: "O+", label: "O-" },
  { value: "AB+", label: "AB-" },
  { value: "AB+", label: "AB-" },
];

const education_and_training = [
  { value: "educational_qualifications", label: "Educational Qualifications" },
  { value: "professional_certifications", label: "Professional Certifications" },
  { value: "skills_and_competencies", label: "Skills and Competencies" }
];

const gender = [
  { value: "", label: "Choose" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

const employeeTypes = [
  { value: "", label: "Choose" },
  { value: "full_time", label: "Full-Time" },
  { value: "part_time", label: "Part-Time" }
]

const employeeCategories = [
  { value: "", label: "Choose" },
  { value: "executive", label: "Executive" },
  { value: "staff", label: "Staff" }
]

const jobGradeLevels = [
  { value: "", label: "Choose" },
  { value: "entry", label: "Entry-Level" },
  { value: "intermediate", label: "Intermediate-Level" },
  { value: "senior", label: "Senior-Level" },
  { value: "lead", label: "Lead/Manager-Level" },
  { value: "director", label: "Director-Level" },
  { value: "executive", label: "Executive-Level" },
  { value: "specialized", label: "Specialized/Principal" }
];

const payFrequencies = [
  { value: "", label: "Choose" },
  { value: "weekly", label: "Weekly" },
  { value: "biweekly", label: "Biweekly" },
  { value: "semimonthly", label: "Semi-Monthly" },
  { value: "monthly", label: "Monthly" },
  { value: "annually", label: "Annually" }
];

const sriLankaProvinces = [
  { value: "", label: "Choose" },
  { value: "central", label: "Central" },
  { value: "eastern", label: "Eastern" },
  { value: "northern", label: "Northern" },
  { value: "northCentral", label: "North Central" },
  { value: "northWestern", label: "North Western" },
  { value: "sabaragamuwa", label: "Sabaragamuwa" },
  { value: "southern", label: "Southern" },
  { value: "uva", label: "Uva" },
  { value: "western", label: "Western" }
];


const customStyles = {
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'yellow', // Set the background color of selected options to yellow
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'black', // Optionally, set the label color inside the selected option
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'black', // Set the color of the remove (x) icon
    ':hover': {
      backgroundColor: 'yellow', // Change hover color to yellow
      color: 'black', // Change the icon color on hover
    },
  }),
};
const EmployeesGrid = () => {

  const startOfCurrentMonth = dayjs().startOf('month');
  const endOfCurrentMonth = dayjs().endOf('month');
  const [selectedRange, setSelectedRange] = useState([
    // startOfCurrentMonth,
    // endOfCurrentMonth
  ]);
  const formDataToSend = new FormData();
  const [fileLists, setFileLists] = useState({});
  //const [column, setColunm] = useState(c)
  const [fileListsForEdit, setFileListsForEdit] = useState({});
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [loading3, setLoading3] = useState(false)
  const [loading4, setLoading4] = useState(false)
  const [loading5, setLoading5] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [reason, setReason] = useState("")
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadedPic, setUploadedPic] = useState("")
  const [uploading, setUploading] = useState(false);
  const [designations, setDesignations] = useState([])
  const [departments, setDepartments] = useState([])
  const [allowances, setAllowances] = useState([])
  const [search, setSearch] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [record, setRecord] = useState("")
  const [nationalitiesOptions, setNationality] = useState([]);
  const [shifts, setShifts] = useState([])
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [employeeData, setEmployeeData] = useState({
    data: [],
    page: 1,
    limit: 10,
    total: 0
  });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    dateOfBirth: null,
    joiningDate: null,
    departmentId: '',
    designationId: '',
    epfNumber: '',
    basicSalary: '',
    employeeId: '',
    workLocation: '',
    dateOfTermination: '',
    nationality: '',
    shift: '',
    bloodGroup: '',
    gender: '',
    employeeType: '',
    contractPeropd: '',
    employeeCategory: '',
    jobGrade: '',
    payFrequency: "",
    allowances: [],
    payeeTaxApplicable: false,
    overtimeEligibility: false,
    emergencyNo1: '',
    emergencyNo2: '',
    address: '',
    city: '',
    province: '',
    password: '',
    conformPassword: ''
  });

  const storedUserData = localStorage.getItem("user");
  const userData = JSON.parse(storedUserData);
  const id = userData?.data?.uid; //
  const [userRightssub, setUserRightssub] = useState([]);
  useEffect(() => {
    const fetchUserRightssub = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/userright/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user rights");
        }
        const data = await response.json();
        console.log("Fetched User Sub Data:", data.data);
        setUserRightssub(data.data || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching data:", error);
        setUserRightssub([]); // Set to empty array in case of error
      }
    };
  
    fetchUserRightssub(); // Fetch the data on component mount
  }, [id]);
  const hasJobRightWithAdd = userRightssub.some(right => right.right === 'Employees' && right.adds !== 1);
  const hasJobRightWithedit = userRightssub.some(right => right.right === 'Employees' && right.updates === 1);
  const hasJobRightWithview = userRightssub.some(right => right.right === 'Employees' && right.viewes === 1);  
  const hasJobRightWithedel = userRightssub.some(right => right.right === 'Employees' && right.deletes=== 1); 
 // const hasJobRightWithdown = userRightssub.some(right => right.right === 'Customers' && right.fdownload=== 1); 

  useEffect(() => {
    const initialCalls = async () => {
      fetchNationalities()
      getAllShiftsMethod({ setShiftData: setShifts })
      let data = await getAllAllowances({ search: "", setLoading: setLoading5 })
      setAllowances(data.map((item => ({
        label: item.name,
        value: item._id
      }))))
      getAllDesignations({
        search: "",
        setData: setDesignations,
        setLoading: setLoading2
      })
      getAllDepartments({
        search: "",
        setData: setDepartments,
        setLoading: setLoading2
      })
    }
    initialCalls()
  }, [])

  useEffect(() => {
    if (success) {
      showSuccess("Successfully saved employee!")
      handleCancel()
    }
    if (isDeleted) {
      showSuccess("Successfully disabled employee!")
      setRecord("")
      setReason("")
      setIsModalOpen2(false)
    }
    if (isDeleted || success) {
      getAllEmployeesMethod({
        setEmployeeData,
        setLoading: setLoading3,
        params: { page: employeeData.page, limit: employeeData.limit },
        search
      });
    }
  }, [success, isDeleted])

  useEffect(() => {
    const handler = setTimeout(() => {
      getAllEmployeesMethod({
        setEmployeeData,
        setLoading: setLoading3,
        params: { page: employeeData.page, limit: employeeData.limit },
        search
      });
    }, 600);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);


  const columns = [
    {
      title: 'Profile Pic',
      dataIndex: 'profile_picture',
      key: 'profile_picture',
      render: (profile_picture) => (
        <Image
          src={profile_picture}
          alt="Profile Picture"
          width={75}
          height={75}
          style={{
            borderRadius: '50%',
            objectFit: 'cover',
          }}
          preview={{
            zIndex: 10000,
            visible: previewOpen,
            src: previewImage,
            onVisibleChange: (visible) => setPreviewOpen(visible),
          }}
          onClick={() => {
            setPreviewImage(profile_picture);
            setPreviewOpen(true);
          }}
        />
      ),
      width: "110px"
    },
    {
      title: 'Name',
      key: 'name',
      render: (text, record) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: 'Employee Number',
      dataIndex: 'employee_id',
      key: 'employee_id',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'contact_number',
      key: 'contact_number'
    },
    {
      title: 'Department',
      dataIndex: ['department', 'departmentName'],
      key: 'department.departmentName',
    },
    {
      title: 'Designation',
      dataIndex: ['designation', 'designationName'],
      key: 'designation.designationName',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <td className="action-table-data">
        
          {hasJobRightWithview  && (
               <Link className="me-2 p-2"
               onClick={() => {
                 setDataFunc(record, false)
               }}
             >
               <i data-feather="download" className="feather-eye"></i>
             </Link> 
          )}
          { hasJobRightWithedit && (
              <Link className="me-2 p-2"
                onClick={() => {
                setDataFunc(record, true)
                }}
                >
                <i data-feather="download" className="feather-edit"></i>
              </Link>
          )}
          { hasJobRightWithedel && (
               <Popconfirm
               title="Delete the Employee"
               description="Are you sure to disable this Employee?"
               onConfirm={() => {
                 setIsModalOpen2(true)
                 setRecord(record)
               }}
               onCancel={() => {
                 console.log("c")
               }}
               okText="Yes"
               cancelText="No"
             >
               <Link className="confirm-text p-2"
   
               >
                 <i data-feather="x-circle" className="feather-x-circle"></i>
               </Link>
             </Popconfirm>
          )}



         

        </td>
      )
    }
  ];

  const setDataFunc = (record, isEdit) => {

    setSelectedRange(record?.contract_period.split(",").map(dateString => dayjs(dateString)))
    setFormData({
      firstName: record?.first_name || '',
      lastName: record?.last_name || '',
      email: record?.email || '',
      contactNumber: record?.contact_number || '',
      dateOfBirth: record?.date_of_birth.split("T")[0] || null,
      joiningDate: record?.joining_date.split("T")[0] || null,
      departmentId: record?.department_id || '',
      designationId: record?.designation_id || '',
      epfNumber: record?.epf_number || '',
      basicSalary: record?.basic_salary || '',
      workLocation: record?.work_location || '',
      dateOfTermination: record?.date_of_termination.split("T")[0] || '',
      nationality: record?.nationality || '',
      shift: record?.shift || '',
      bloodGroup: record?.blood_group || '',
      gender: record?.gender || '',
      employeeType: record?.employee_type || '',
      contractPeropd: record?.contract_period.split(",") || '',
      employeeCategory: record?.employee_category || '',
      jobGrade: record?.job_grade || '',
      payFrequency: record?.pay_frequency || "",
      allowances: record?.allowances.map(item1 => {
        const matchItem = allowances.filter(item2 => item1.name === item2.value)
        return {
          id: {
            value: item1.name,
            label: matchItem[0].label
          },
          amount: item1.amount
        }
      }) || [],
      payeeTaxApplicable: record?.payee_tax_applicable || false,
      overtimeEligibility: record?.overtime_eligibility || false,
      emergencyNo1: record?.emergency_no_1 || '',
      emergencyNo2: record?.emergency_no_2 || '',
      address: record?.address || '',
      city: record?.city || '',
      province: record?.province || '',
      employeeId: record._id
    })
    if (isEdit) {
      if (record && Array.isArray(record.skills_doc_urls) && record.skills_doc_urls.length > 0) {
        setFileListsForEdit(prev => ({ ...prev, skills_and_competencies: [...record.skills_doc_urls] }));
      }
      if (record && Array.isArray(record.qualifications_doc_urls) && record.qualifications_doc_urls.length > 0) {
        setFileListsForEdit(prev => ({ ...prev, educational_qualifications: [...record.qualifications_doc_urls] }));
      }
      if (record && Array.isArray(record.certifications_doc_urls) && record.certifications_doc_urls.length > 0) {
        setFileListsForEdit(prev => ({ ...prev, professional_certifications: [...record.certifications_doc_urls] }));
      }
      setIsEdit(true)
    } else {
      if (record && Array.isArray(record.skills_doc_urls) && record.skills_doc_urls.length > 0) {
        setFileLists(prev => ({ ...prev, skills_and_competencies: [...record.skills_doc_urls] }));
      }
      if (record && Array.isArray(record.qualifications_doc_urls) && record.qualifications_doc_urls.length > 0) {
        setFileLists(prev => ({ ...prev, educational_qualifications: [...record.qualifications_doc_urls] }));
      }
      if (record && Array.isArray(record.certifications_doc_urls) && record.certifications_doc_urls.length > 0) {
        setFileLists(prev => ({ ...prev, professional_certifications: [...record.certifications_doc_urls] }));
      }
      setIsView(true)
    }
    setIsModalOpen(true)
    setUploadedPic(record.profile_picture)
  }

  const fetchNationalities = async () => {
    try {
      const response = await fetch('https://restcountries.com/v3.1/all');
      const data = await response.json();
      const nationalities = data.reduce((acc, country) => {
        if (country.demonyms && country.demonyms.eng) {
          acc.push(country.demonyms.eng.m);
          acc.push(country.demonyms.eng.f);
        }
        return acc;
      }, []);
      const uniqueNationalities = [...new Set(nationalities)];
      const processedNationalityOptions = [{ value: "", label: "Choose" }, ...uniqueNationalities.map(nationality => ({
        value: nationality,
        label: nationality
      }))]
      setNationality(processedNationalityOptions);
    } catch (error) {
      console.error('Error fetching nationalities:', error);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    handleReset()
  };

  const handleSelectChange = (selectedOption, name) => {
    setFormData({
      ...formData,
      [name]: selectedOption.value,
    });
  };

  const handleCustomRequest = async ({ file, onSuccess, onError }) => {
    setUploading(true);
    formDataToSend.append('profilePicture', file);
    try {
      onSuccess(true);
      message.success(`${file.name} uploaded successfully`);
    } catch (error) {
      onError(error);
      message.error(`${file.name} upload failed`);
    } finally {
      setUploading(false);
    }
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleDateChange = (date, dateString, field) => {
    setFormData(prev => ({ ...prev, [field]: dateString }));
  };

  const handleSubmit = () => {
    if (Object.entries(formData)
      .filter(([key]) => key !== 'employeeId' && key !== "emergencyNo2")
      .some(([, value]) => value === "" || value === null) || (!isEdit && fileList.length === 0)) {
      showWarning("Need to fill all required fields!");
      return;
    }

    if (!validateEmail(formData.email)) {
      showWarning("Email is not valid!");
      return;
    }

    if (!validateSriLankaPhoneNumber(formData.contactNumber)) {
      showWarning("Phone number is not valid");
      return;
    }

    if (!validateSriLankaPhoneNumber(formData.emergencyNo1)) {
      showWarning("Emergency number 01 is not valid");
      return;
    }

    if (formData.emergencyNo2 && !validateSriLankaPhoneNumber(formData.emergencyNo2)) {
      showWarning("Emergency number 02 is not valid");
      return;
    }

    if (formData.password !== formData.conformPassword) {
      showWarning("Conform password and Password don't match!");
      return;
    }

    const formDataToSend = new FormData();

    Object.keys(formData).forEach(key => {
      if (key === "allowances") {
        formDataToSend.append(key, JSON.stringify(formData[key].map(item => ({
          name: item.id.value || item.id,
          amount: item.amount
        }))));
        return;
      }
      formDataToSend.append(key, formData[key]);
    });
    if (fileList.length > 0) {
      formDataToSend.append('profilePicture', fileList[0].originFileObj);
    }

    Object.keys(fileLists).forEach(key => {
      fileLists[key].forEach(file => {
        formDataToSend.append(key, file.originFileObj);
      });
    });

    createEmployeeMethod({
      formdata: formDataToSend,
      setLoading,
      setSuccess,
    });
  };
  const handleReset = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      contactNumber: '',
      dateOfBirth: null,
      joiningDate: null,
      departmentId: '',
      designationId: '',
      epfNumber: '',
      basicSalary: '',
      employeeId: '',
      workLocation: '',
      dateOfTermination: '',
      nationality: '',
      shift: '',
      bloodGroup: '',
      gender: '',
      employeeType: '',
      contractPeropd: '',
      employeeCategory: '',
      jobGrade: '',
      payFrequency: "",
      allowances: [],
      payeeTaxApplicable: false,
      overtimeEligibility: false,
      emergencyNo1: '',
      emergencyNo2: '',
      address: '',
      city: '',
      province: '',
      password: '',
      conformPassword: ''
    })
    setFileList([])
    setPreviewImage("")
    setUploadedPic("")
    setIsEdit(false)
    setIsView(false)
    setFileLists({})
    setFileListsForEdit({})
    setSelectedRange([])
  }

  const showSuccess = (str) => {
    return Swal.fire({
      title: "Good job!",
      text: str,
      icon: "success"
    });
  }

  const showWarning = (str) => {
    return Swal.fire({
      title: "Error",
      text: str,
      icon: "error",
    });
  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateSriLankaPhoneNumber = (phoneNumber) => {
    const sriLankaPhoneRegex = /^0[1-9]\d{8}$/;
    return sriLankaPhoneRegex.test(phoneNumber);
  };

  const handleTableChange = (pagination) => {
    getAllEmployeesMethod({
      setEmployeeData,
      setLoading: setLoading3,
      params: { page: pagination.current, limit: pagination.pageSize },
      search
    });
  };

  const handleChange2 = (key, { file, fileList }) => {
    console.log(fileList)
    if (fileList.length > 4) {
      showWarning("You can only upload max count of pdf is 4!")
      return
    }
    const fileType = file.name.split('.').pop().toLowerCase();
    const isValidType = ['pdf'].includes(fileType);

    if (!isValidType) {
      showWarning('You can only upload PDF files!');
      return;
    }

    setFileLists((prevFileLists) => ({
      ...prevFileLists,
      [key]: fileList,
    }));
  };

  const handlePreview2 = (file, type) => {
    const fileUrl = file.url || URL.createObjectURL(file.originFileObj);
    setPreviewFile(fileUrl);
    if (type === "doc") return
    setPreviewVisible(true);
  };

  const handlePreview3 = (file) => {
    setPreviewFile(file);
    setPreviewVisible(true);
  };

  const renderFileIcon = (file) => {
    const fileType = file.name.split('.').pop().toLowerCase();
    if (fileType === 'pdf') {
      return <AiFillFilePdf style={{ fontSize: 24, color: 'red', cursor: 'pointer' }} onClick={() => handlePreview2(file, "pdf")} />;
    } else if (fileType === 'doc' || fileType === 'docx') {
      return <FaFileWord style={{ fontSize: 24, color: 'blue' }} onClick={() => handlePreview2(file, "doc")} />;
    } else {
      return <p>{file.name}</p>;
    }
  };

  const renderFileIconForView = (file) => {
    const fileType = file.split('.').pop().toLowerCase();
    if (fileType === 'pdf') {
      return <AiFillFilePdf style={{ fontSize: 24, color: 'red', cursor: 'pointer' }} onClick={() => handlePreview3(file)} />;
    } else if (fileType === 'doc' || fileType === 'docx') {
      return <FaFileWord style={{ fontSize: 24, color: 'blue' }} />;
    } else {
      return <p>{file.name}</p>;
    }
  };

  const handleDelete = (key, file) => {
    setFileLists((prevFileLists) => ({
      ...prevFileLists,
      [key]: prevFileLists[key].filter(item => item.uid !== file.uid),
    }));
  };

  const columns2 = [
    {
      title: 'Type of Qualification',
      dataIndex: 'label',
      key: 'label',
      render: (text) => (
        <>
          {text} <p style={{ display: "inline", color: "#C0C0C0" }}>{`(Optional)`}</p>
        </>
      )
    },
    {
      title: 'Upload',
      key: 'upload',
      render: (_, record) => (
        <>
          <Upload
            listType="text"
            fileList={fileLists[record.value] || []}
            onChange={(info) => handleChange2(record.value, info)}
            showUploadList={false}
            multiple={true} // Enable multiple file uploads
            disabled={isView}
          //maxCount={4}
          >
            {isView && !Array.isArray(fileLists[record.value]) ? <FaFileExcel size="20px" /> : isView ? "" : <Button icon={<UploadOutlined />}>Upload</Button>}
          </Upload>

          {!isView && fileLists[record.value]?.map((file) => (
            <div key={file.uid} style={{ marginTop: 0, display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
              <div style={{ marginTop: 0, display: 'flex', alignItems: 'center' }}>
                {renderFileIcon(file)}
                <p style={{ marginLeft: 8, marginTop: "0px" }}>{file.name}</p>
              </div>
              <DeleteOutlined
                style={{ marginLeft: "8px", color: 'red', cursor: 'pointer' }}
                onClick={() => handleDelete(record.value, file)}
              />
            </div>
          ))}
          {isView && fileLists[record.value]?.map((file) => (
            <div key={file.uid} style={{ marginTop: 0, display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
              <div style={{ marginTop: 0, display: 'flex', alignItems: 'center' }}>
                {renderFileIconForView(file)}
                <p style={{ marginLeft: 8, marginTop: "0px" }}>{file.name}</p>
              </div>
            </div>
          ))}
        </>
      ),
    },
  ];


  const columns3 = [
    {
      title: 'Type of Qualification',
      dataIndex: 'label',
      key: 'label',
      render: (text) => (
        <>
          {text} <p style={{ display: "inline", color: "#C0C0C0" }}>{`(Optional)`}</p>
        </>
      )
    },
    {
      title: 'Uploaded Docs/Pdfs',
      key: 'upload',
      render: (_, record) => (
        <>
          <Upload
            listType="text"
            fileList={fileListsForEdit[record.value] || []}
            onChange={(info) => handleChange2(record.value, info)}
            showUploadList={false} // Hide default list to customize display
            disabled={true}
          >
            {fileListsForEdit[record.value]?.length >= 1 ? null : (
              isEdit ? <FaFileExcel size="20px" /> : <Button icon={<UploadOutlined />}>Upload</Button>
            )}
          </Upload>
          {fileListsForEdit[record.value]?.map((file) => (
            <div key={file} style={{ marginTop: 0, display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
              <div style={{ marginTop: 0, display: 'flex', alignItems: 'center' }}>
                {renderFileIconForView(file)}
                <p style={{ marginLeft: 8, marginTop: "0px" }}>{file.name}</p>
              </div>
            </div>
          ))}
        </>
      )
    },
    {
      title: 'Upload',
      key: 'upload',
      render: (_, record) => (
        <>
          <Upload
            listType="text"
            fileList={fileLists[record.value] || []}
            onChange={(info) => handleChange2(record.value, info)}
            showUploadList={false}
            multiple={true}
          // maxCount={4}
          >
            {isView && !Array.isArray(fileLists[record.value]) ? <FaFileExcel size="20px" /> : isView ? "" : <Button icon={<UploadOutlined />}>Upload</Button>}
          </Upload>
          {!isView && fileLists[record.value]?.map((file) => (
            <div key={file.uid} style={{ marginTop: 0, display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
              <div style={{ marginTop: 0, display: 'flex', alignItems: 'center' }}>
                {renderFileIcon(file)}
                <p style={{ marginLeft: 8, marginTop: "0px" }}>{file.name}</p>
              </div>
              <DeleteOutlined
                style={{ marginLeft: "8px", color: 'red', cursor: 'pointer' }}
                onClick={() => handleDelete(record.value, file)}
              />
            </div>
          ))}
          {/* {isView && fileLists[record.value]?.map((file) => (
            <div key={file} style={{ marginTop: 0, display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
              <div style={{ marginTop: 0, display: 'flex', alignItems: 'center' }}>
                {renderFileIconForView(file)}
                <p style={{ marginLeft: 8, marginTop: "0px" }}>{file.name}</p>
              </div>
            </div>
          ))} */}
        </>
      ),
    },
  ];

  const handleDateRangeChange = (dates, dateString) => {
    if (dates === null) {
      setSelectedRange([startOfCurrentMonth, endOfCurrentMonth])
      setFormData({
        ...formData,
        contractPeropd: "",
      });
      return
    }
    setSelectedRange(dates);
    setFormData({
      ...formData,
      contractPeropd: dateString,
    });
  };

  const handleAmountChange = (id, value) => {
    if (parseFloat(value) <= 0) {
      showWarning("Amount should be greater than 0")
      return
    }
    setFormData((prevData) => ({
      ...prevData,
      allowances: prevData.allowances.map((allowance) =>
        allowance.id === id ? { ...allowance, amount: value } : allowance
      ),
    }));
  };

  const handleDeleteAllowance = (id) => {
    setFormData((prevData) => ({
      ...prevData,
      allowances: prevData.allowances.filter((allowance) => allowance.id.value !== id.value),
    }));
  };

  const columnsForAllowance = [
    {
      title: 'Allowance Type',
      dataIndex: 'id',
      key: 'id',
      render: (id) => allowances.find((allowance) => allowance.value === id.value)?.label,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount, record) => (
        <Input
          type='number'
          value={amount}
          onChange={(e) => handleAmountChange(record.id, e.target.value)}
          disabled={isView}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Link
          type="link"
          danger
          onClick={() => handleDeleteAllowance(record.id)}
          disabled={isView}
        >
          <i data-feather="x-circle" className="feather-x-circle"></i>
        </Link>
      ),
    },
  ];
  useEffect(() => {
    console.log(formData.allowances)
  }, [formData])
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Employees</h4>
              <h6>Manage your employees</h6>
            </div>
          </div>
          <div className="page-btn">
          {hasJobRightWithAdd && (
              <a className="btn btn-added" onClick={() => setIsModalOpen(true)}>
              <PlusCircle className="me-2" />
              Add New Employee
            </a>
          )}

          </div>
        </div>

        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Link className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
                <div className="wordset">
                  <ul>
                    <li>
                      <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="pdf">
                        <img src="assets/img/icons/pdf.svg" alt="pdf" />
                      </a>
                    </li>
                    <li>
                      <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="excel">
                        <img src="assets/img/icons/excel.svg" alt="excel" />
                      </a>
                    </li>
                    <li>
                      <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="print">
                        <img src="assets/img/icons/printer.svg" alt="print" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="search-set">
                <a href="#" className="btn btn-filter" id="filter_search">
                  <Sliders className="me-2" />Filter
                </a>
              </div>
            </div>
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={employeeData.data}
                rowKey="_id"
                pagination={{
                  current: employeeData.page,
                  pageSize: employeeData.limit,
                  total: employeeData.total
                }}
                loading={loading3}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        zIndex={1200}
        open={isModalOpen}
        onOk={handleOk}
        width="75vw"
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        closable={false}
      >
        <form>
          <div className="card">
            <div className="card-body">
              <div className="new-employee-field">
                <div className="modal-header custom-modal-header">
                  <div className="page-title">
                    <h4>{isEdit ? "Edit" : isView ? "View" : "Create"} Employee</h4>
                    {!isView && <p className="mb-0">Fill in the required fields to create an Employee</p>}
                  </div>
                </div>
                <div style={{ marginBottom: "30px", display: "flex", justifyContent: "center" }}>
                  <div style={{ textAlign: "center" }}>
                    {(isEdit || isView) &&
                      <label className="form-label" style={{ display: "block", marginBottom: "10px" }}>
                        Uploaded Profile Picture
                      </label>}
                    {(isEdit || isView) && (
                      <Image
                        src={uploadedPic}
                        alt="Profile Picture"
                        width={120}
                        height={120}
                        style={{
                          objectFit: "cover",
                          borderRadius: "50%",
                          border: "2px solid #ddd",
                          padding: "5px",
                          boxShadow: "0 4px 8px rgba(0,0,0,0.1)"
                        }}
                        preview={{
                          zIndex: 10000,
                          visible: previewOpen,
                          src: previewImage,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                        }}
                        onClick={() => {
                          setPreviewImage(uploadedPic);
                          setPreviewOpen(true);
                        }}
                      />
                    )}
                  </div>
                </div>
                {/*<div>
                      {
                          isEdit && <Image
                              src={uploadedPic}
                              alt="Profile Picture"
                              width={100}
                              height={100}
                              style={{

                                objectFit: 'cover',
                              }}
                              preview={{
                                zIndex: 10000,
                                visible: previewOpen,
                                src: previewImage,
                                onVisibleChange: (visible) => setPreviewOpen(visible),
                              }}
                              onClick={() => {
                                setPreviewImage(uploadedPic);
                                setPreviewOpen(true);
                              }}
                          />
                      }
                    </div> */}
                <div className="profile-pic-upload">
                  {!isView &&
                    <div className="input-blocks mb-0">
                      <ImgCrop>
                        <Upload
                          listType="picture-circle"
                          fileList={fileList}
                          onPreview={handlePreview}
                          onChange={handleChange}
                          customRequest={handleCustomRequest}
                          disabled={uploading}
                        >
                          {fileList.length >= 1 ? null : uploadButton}
                        </Upload>
                      </ImgCrop>

                      {previewImage && (
                        <Image
                          wrapperStyle={{ display: "none" }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(""),
                          }}
                          src={previewImage}
                        />
                      )}
                      <label className="form-label">{isEdit && "Upload New"}Profile Picture</label>
                    </div>
                  }
                </div>
                <div className="row">
                  <div className="card-title-head">
                    <h6>
                      <span>
                        <Info className="feather-edit" />
                      </span>
                      Employee Information
                    </h6>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.firstName}
                        onChange={(e) => handleInputChange(e, 'firstName')}
                        disabled={isView}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.lastName}
                        onChange={(e) => handleInputChange(e, 'lastName')}
                        disabled={isView}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        value={formData.email}
                        onChange={(e) => handleInputChange(e, 'email')}
                        disabled={isView}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.contactNumber}
                        onChange={(e) => handleInputChange(e, 'contactNumber')}
                        disabled={isView}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Work Location</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.workLocation}
                        onChange={(e) => handleInputChange(e, 'workLocation')} disabled={isView}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-blocks">
                      <label>Date of Birth</label>
                      <div className="input-groupicon calender-input">
                        <DatePicker
                          value={formData.dateOfBirth ? moment(formData.dateOfBirth, 'YYYY-MM-DD') : null}
                          onChange={(date, dateString) => handleDateChange(date, dateString, 'dateOfBirth')}
                          className="form-control"
                          placeholder="Choose Date"
                          disabled={isView}

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-blocks">
                      <label>Date of Termination</label>
                      <div className="input-groupicon calender-input">
                        <DatePicker
                          value={formData.dateOfTermination ? moment(formData.dateOfTermination, 'YYYY-MM-DD') : null}
                          onChange={(date, dateString) => handleDateChange(date, dateString, 'dateOfTermination')}
                          className="form-control"
                          placeholder="Choose Date"
                          disabled={isView}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-blocks">
                      <label>Joining Date</label>
                      <div className="input-groupicon calender-input">
                        <DatePicker
                          value={formData.joiningDate ? moment(formData.joiningDate, 'YYYY-MM-DD') : null}
                          onChange={(date, dateString) => handleDateChange(date, dateString, 'joiningDate')}
                          className="form-control"
                          placeholder="Choose Date"
                          disabled={isView}
                        />
                      </div></div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Nationality</label>
                      <Select
                        isDisabled={isView}
                        name="nationality"
                        value={nationalitiesOptions.find(option => option.value === formData.nationality)}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, 'nationality')}
                        className="select"
                        options={nationalitiesOptions}
                        placeholder="Choose"

                      />

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Select Shift</label>
                      <Select
                        isDisabled={isView}
                        size="large"
                        showSearch
                        placeholder="Choose"
                        value={shifts.find(option => option.value === formData.shift)}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, 'shift')}
                        options={shifts}
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Blood Group</label>
                      <Select
                        isDisabled={isView}
                        name="bloodGroup"
                        value={bloodgroup.find(option => option.value === formData.bloodGroup)}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, 'bloodGroup')}
                        className="select"
                        options={bloodgroup}
                        placeholder="Choose"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Gender</label>
                      <Select
                        isDisabled={isView}
                        name="gender"
                        value={gender.find(option => option.value === formData.gender)}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, 'gender')}
                        className="select"
                        options={gender}
                        placeholder="Choose"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Employee Type</label>
                      <Select
                        isDisabled={isView}
                        name="gender"
                        value={employeeTypes.find(option => option.value === formData.employeeType)}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, 'employeeType')}
                        className="select"
                        options={employeeTypes}
                        placeholder="Choose"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Contract Period</label>
                      <RangePicker
                        onChange={handleDateRangeChange}
                        value={selectedRange}
                        format="YYYY-MM-DD"
                        disabled={isView}
                      // className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Employee Category</label>
                      <Select
                        isDisabled={isView}
                        name="gender"
                        value={employeeCategories.find(option => option.value === formData.employeeCategory)}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, 'employeeCategory')}
                        className="select"
                        options={employeeCategories}
                        placeholder="Choose"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3" style={{
                      display: "flex",
                      flexDirection: "column"
                    }}>
                      <label className="form-label">Department</label>
                      <div className="input-blocks">
                        {
                          loading2 ? <Spin size='small' /> : <select
                            className="form-select"
                            value={formData.departmentId}
                            onChange={(e) => setFormData(prev => ({ ...prev, departmentId: e.target.value }))}
                          >
                            <option value={"pending"}>Choose</option>
                            {departments &&
                              departments.map((dep, index) => (
                                <option key={index} value={dep._id}>
                                  {dep.departmentName}
                                </option>
                              ))}
                          </select>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3" style={{
                      display: "flex",
                      flexDirection: "column"
                    }}>
                      <label className="form-label">Designation</label>
                      <div className="input-blocks">
                        {
                          loading2 ? <Spin size='small' /> : <select
                            className="form-select"
                            value={formData.designationId}
                            onChange={(e) => setFormData(prev => ({ ...prev, designationId: e.target.value }))}
                          >
                            <option value={"pending"}>Choose</option>
                            {designations &&
                              designations.map((des, index) => (
                                <option key={index} value={des._id}>
                                  {des.designationName}
                                </option>
                              ))}
                          </select>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6"><div className="mb-3">
                    <label className="form-label">Job Grade/Level</label>
                    <Select
                      isDisabled={isView}
                      name="gender"
                      value={jobGradeLevels.find(option => option.value === formData.jobGrade)}
                      onChange={(selectedOption) => handleSelectChange(selectedOption, 'jobGrade')}
                      className="select"
                      options={jobGradeLevels}
                      placeholder="Choose"
                    />
                  </div>
                  </div>
                  <div className="col-lg-4 col-md-6" style={{ marginTop: "-20px" }}>
                    <div className="mb-3">
                      <label className="form-label">Pay Frequency</label>
                      <Select
                        isDisabled={isView}
                        name="gender"
                        value={payFrequencies.find(option => option.value === formData.payFrequency)}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, 'payFrequency')}
                        className="select"
                        options={payFrequencies}
                        placeholder="Choose"
                      />
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10PX" }}>
                  <div className="card-title-head">
                    <h6>
                      <span>
                        <Info className="feather-edit" />
                      </span>
                      Compensation and Benefits
                    </h6>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">EPF Number</label>
                      <input
                        disabled={isView}
                        type="text"
                        className="form-control"
                        value={formData.epfNumber}
                        onChange={(e) => handleInputChange(e, 'epfNumber')}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">Basic Salary</label>
                      <input
                        disabled={isView}
                        type="number"
                        className="form-control"
                        value={formData.basicSalary}
                        onChange={(e) => {
                          if (parseFloat(e.target.value) <= 0) {
                            showWarning("Cant be negative or zero")
                            return
                          }
                          handleInputChange(e, 'basicSalary')
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    {loading5 ? <Spin size='small' /> : <div className="mb-3">
                      <label className="form-label">Allowance Types</label>
                      <Select
                        isDisabled={isView}
                        value={formData.allowances.map(item => item.id)}
                        onChange={(selectedOptions) => {
                          const uniqueOptions = selectedOptions.filter(
                            (option, index, self) =>
                              index === self.findIndex((t) => t.value === option.value)
                          );

                          setFormData((prev) => ({
                            ...prev,
                            allowances: uniqueOptions.map((item) => {
                              const existingAllowance = prev.allowances.find(
                                (allowance) => allowance.id.value === item.value
                              );
                              return {
                                id: item, // Keep the same ID
                                amount: existingAllowance ? existingAllowance.amount : 0, // Retain existing amount or set to 0 for new allowances
                              };
                            }),
                          }));
                        }}
                        className="select"
                        isMulti={true}
                        options={allowances}
                        placeholder="Choose"
                        styles={customStyles}
                      />

                    </div>}
                    {formData.allowances.length > 0 && (
                      <Table
                        dataSource={formData.allowances}
                        columns={columnsForAllowance}
                        pagination={false}
                        rowKey="id"
                      />
                    )}
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <Checkbox
                        disabled={isView}
                        checked={formData.payeeTaxApplicable}
                        onChange={(e) => {
                          setFormData(prev => ({ ...prev, payeeTaxApplicable: e.target.checked }));
                        }}
                      ><p style={{ color: "black" }}>Payee Tax applicable</p>
                      </Checkbox>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <Checkbox
                        disabled={isView}
                        checked={formData.overtimeEligibility}
                        onChange={(e) => {
                          setFormData(prev => ({ ...prev, overtimeEligibility: e.target.checked }));
                        }}
                      ><p style={{ color: "black" }}>Overtime Eligibility</p>
                      </Checkbox>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10PX" }}>
                  <div className="card-title-head">
                    <h6>
                      <span>
                        <Info className="feather-edit" />
                      </span>
                      Other Information
                    </h6>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Emergency No 1</label>
                      <input
                        disabled={isView}
                        type="text"
                        className="form-control"
                        value={formData.emergencyNo1}
                        onChange={(e) => handleInputChange(e, 'emergencyNo1')}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Emergency No 2 <p style={{
                        display: "inline",
                        color: "#C0C0C0"
                      }}>{`(Optional)`}</p></label>
                      <input
                        disabled={isView}
                        type="text"
                        className="form-control"
                        value={formData.emergencyNo2}
                        onChange={(e) => handleInputChange(e, 'emergencyNo2')}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Address</label>
                      <input
                        disabled={isView}
                        type="text"
                        className="form-control"
                        value={formData.address}
                        onChange={(e) => handleInputChange(e, 'address')}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">City</label>
                      <input
                        disabled={isView}
                        type="text"
                        className="form-control"
                        value={formData.city}
                        onChange={(e) => handleInputChange(e, 'city')}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Province</label>
                      <Select
                        isDisabled={isView}
                        name="nationality"
                        value={sriLankaProvinces.find(option => option.value === formData.province)}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, 'province')}
                        className="select"
                        options={sriLankaProvinces}
                        placeholder="Choose"
                      />
                    </div>
                  </div>
                </div>
                {(!isView && !isEdit) && <div className="row" style={{ marginTop: "10PX" }}>
                  <div className="card-title-head">
                    <h6>
                      <span>
                        <Info className="feather-edit" />
                      </span>
                      Password Information
                    </h6>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Password</label>
                      <input
                        disabled={isView}
                        type="password"
                        className="form-control"
                        value={formData.password}
                        onChange={(e) => handleInputChange(e, 'password')}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Confirm Password</label>
                      <input
                        disabled={isView}
                        type="password"
                        className="form-control"
                        value={formData.conformPassword}
                        onChange={(e) => handleInputChange(e, 'conformPassword')}
                      />
                    </div>
                  </div>
                </div>}
                <div className="row" style={{ marginTop: "10PX" }}>
                  <div className="card-title-head">
                    <h6>
                      <span>
                        <Info className="feather-edit" />
                      </span>
                      Education and Training
                    </h6>
                  </div>
                  {isEdit && <Alert
                    message="Warning"
                    description="If you upload new documents for the sections below, your existing documents will be removed, and the new documents will be saved"
                    type="warning"
                    showIcon
                    closable
                  />}
                  <Table
                    columns={isEdit ? columns3 : columns2} // Use slice to get a copy of the array with only the first column
                    dataSource={education_and_training}
                    rowKey="value"
                    pagination={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="" style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <button
              type="button"
              className="btn btn-cancel"
              onClick={() => {
                handleCancel();
              }}
            >
              Close
            </button>
            {!isView && <div className="">
              {!isEdit && <button
                type="button"
                className="btn btn-reset me-2"
                onClick={handleReset}
              >
                Reset
              </button>}
              <button type='button' className="btn btn-submit ml-0" onClick={handleSubmit} disabled={loading}>
                {loading ? <Spin size='small' /> : "Save"}
              </button>
            </div>}
          </div>
        </form>
      </Modal>
      <Modal
        open={previewVisible}
        zIndex={1200}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        width={800}
        transitionName=""
      >
        <iframe
          src={previewFile}
          width="100%"
          height="600px"
          style={{ border: 'none' }}
          title="PDF Preview"
        />
        <button
          type="button"
          className="btn btn-cancel"
          onClick={() => {
            setPreviewVisible(false)
          }}
        >
          Close
        </button>
      </Modal>
      <Modal zIndex={1200}
        open={isModalOpen2}
        onOk={() => {
          setIsModalOpen2(false)
          setReason("")
          setRecord("")
        }}
        width="45vw"
        onCancel={() => {
          setIsModalOpen2(false)
          setReason("")
          setRecord("")
        }}
        footer={null}
        maskClosable={false}
        closable={false}
      >
        <div className="card">
          <div className="card-body">
            <form>
              <div className="col-lg-12 col-md-12">
                <div className="mb-3">
                  <label className="form-label">Resigning Reason</label>
                  <textarea
                    rows={5}
                    type="text"
                    className="form-control"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </div>
              </div>
              <div className="" style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}>
                <div className="">
                  <button type='button' className="btn btn-submit ml-0"
                    onClick={() => {
                      if (!reason) {
                        showWarning("need a reason for disable employee!")
                        return
                      }
                      deleteEmployeeMethod({
                        id: record._id,
                        setIsDelete: setIsDeleted,
                        reason,
                        setLoading: setLoading4
                      })
                    }}
                    disabled={loading4}
                  >
                    {loading4 ? <Spin size='small' /> : "Disable"}
                  </button>
                </div>

                <button
                  type="button"
                  className="btn btn-cancel"
                  onClick={() => {
                    setIsModalOpen2(false)
                    setReason("")
                    setRecord("")
                  }}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>

      </Modal>
    </div>
  );

};

export default EmployeesGrid;
