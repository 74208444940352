import React, { useState } from "react";
//import { Link } from 'react-router-dom'
import { createRole } from "../../../../src/service/userRoleApiServices";
const AddRole = () => {
  const [roleName, setRoleName] = useState("");
  const [functionName, setFunctionName] = useState("");
  const [permissions, setPermissions] = useState([]);

  {
    /*
    const handleGetRoles = async () => {
      try {
        const rolesData = await getRoles();
        setRoles(rolesData);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    console.log(roles, "Updated Roles addrole.jsx");
  */
  }

  const handlePermissions = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setPermissions([...permissions, value]);
    } else {
      setPermissions(permissions.filter((permission) => permission !== value));
    }
  };

  const handleCreateRole = async () => {
    try {
      const response = await createRole(roleName, functionName, permissions);
      console.log("Role created:", response);
      setRoleName("");
      setFunctionName("");
      setPermissions([]);
    } catch (error) {
      console.error("Error creating role:", error);
    }
  };

  return (
    <div>
      {/* Add Role */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create Role</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="mb-0">
                      <label className="form-label">Role Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={roleName}
                        onChange={(e) => setRoleName(e.target.value)}
                      />
                    </div>
                    <div className="mb-0">
                      <label className="form-label">Function Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={functionName}
                        onChange={(e) => setFunctionName(e.target.value)}
                      />
                    </div>
                    <div className="mb-0">
                      <label className="form-label">Permissions</label>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value="View"
                          id="permissionView"
                          onChange={handlePermissions}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="permissionView"
                        >
                          View
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value="Create"
                          id="permissionCreate"
                          onChange={handlePermissions}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="permissionCreate"
                        >
                          Create
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value="Edit"
                          id="permissionEdit"
                          onChange={handlePermissions}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="permissionEdit"
                        >
                          Edit
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value="Delete"
                          id="permissionDelete"
                          onChange={handlePermissions}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="permissionDelete"
                        >
                          Delete
                        </label>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      {/* <Link to="#" className="btn btn-submit">
                                                Create Role
                                            </Link> */}
                      <button
                        type="button"
                        className="btn btn-submit"
                        data-bs-dismiss="modal"
                        onClick={handleCreateRole}
                      >
                        Create Role
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Role */}
    </div>
  );
};

export default AddRole;
