import React from 'react'
import moment from 'moment';
import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom'
import {PlusCircle, Sliders} from 'react-feather';
// import Select from 'react-select';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Table from '../../core/pagination/newdatatable.jsx'
import AddHolidays from '../../core/modals/hrm/addholidays.jsx';
import EditHolidays from '../../core/modals/hrm/editholidays.jsx';
import { getAllHolidays,deleteHoliday } from '../../service/holidayService.js';
import { API_BASE_URL } from "../../../src/environment";

const Holidays = () => {
    
    const [dataSource, setDataSource] = useState([]);
    const [shouldReloadData, setShouldReloadData] = useState(false)
    const [selectedId, setSelectedId] = useState(null);


    const handleReloadData = () => {
        setShouldReloadData(!shouldReloadData);
    };

    // const oldandlatestvalue = [
    //     { value: 'date', label: 'Sort by Date' },
    //     { value: 'newest', label: 'Newest' },
    //     { value: 'oldest', label: 'Oldest' },
    // ];
    
    const fetchHolidays = async (search = '') => {
        await getAllHolidays({
            search,
            setData: setDataSource,
            
        });
    };


    const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    const id = userData?.data?.uid; //
    const [userRightssub, setUserRightssub] = useState([]);

    useEffect(() => {
    const fetchUserRightssub = async () => {
        try {
        const response = await fetch(`${API_BASE_URL}/userright/${id}`);
        if (!response.ok) {
            throw new Error("Failed to fetch user rights");
        }
        const data = await response.json();
        console.log("Fetched User Sub Data:", data.data);
        setUserRightssub(data.data || []); // Ensure it's always an array
        } catch (error) {
        console.error("Error fetching data:", error);
        setUserRightssub([]); // Set to empty array in case of error
        }
    };

    fetchUserRightssub(); // Fetch the data on component mount
    }, [id]);
    const hasJobRightWithAdd = userRightssub.some(right => right.right === 'Holidays' && right.adds === 1);
    const hasJobRightWithedit = userRightssub.some(right => right.right === 'Holidays' && right.updates === 1);
    // const hasJobRightWithview = userRightssub.some(right => right.right === 'Holidays' && right.viewes === 1);  
    const hasJobRightWithedel = userRightssub.some(right => right.right === 'Holidays' && right.deletes=== 1); 
    // const hasJobRightWithdown = userRightssub.some(right => right.right === 'Holidays' && right.fdownload=== 1);

    useEffect(() => {
        fetchHolidays(); 
    }, [shouldReloadData]);

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Start Date",
            dataIndex: "startDate",
            sorter: (a, b) => a.date.length - b.date.length,
            render: (date) => moment(date).format("DD-MM-YYYY"),
        },
        {
            title: "End Date",
            dataIndex: "endDate",
            sorter: (a, b) => a.date.length - b.date.length,
            render: (date) => moment(date).format("DD-MM-YYYY"),
        },
        {
            title: "Duration",
            dataIndex: "duration",
            sorter: (a, b) => a.duration.length - b.duration.length,
           
        },
        {
            title: "Created On",
            dataIndex: "createdAt",
            sorter: (a, b) => a.createdon.length - b.createdon.length,
            render: (date) => moment(date).format("DD-MM-YYYY"),
        },

        {
            title: "Status",
            dataIndex: "status",
            render: (status) => (
                <span className={`badge ${status ? 'badge-linesuccess' : 'badge-linesuccess'}`}>
                    <Link to="#">{status ? 'Active' : 'Inactive'}</Link>
                </span>
            ),
            sorter: (a, b) => a.status.length - b.status.length,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_,record) => (
                <td className="action-table-data">
                     {hasJobRightWithedit && (
                           <Link
                           className="me-2 p-2"
                           to="#"
                           data-bs-toggle="modal"
                           data-bs-target="#edit-department"
                           onClick={()=>handleEditClick(record._id)}
                            >
                                <i data-feather="edit" className="feather-edit"></i>
                            </Link>
                      )}
                    {hasJobRightWithedel && (
                           <Link className="confirm-text p-2" to="#">
                           <i
                               data-feather="trash-2"
                               className="feather-trash-2"
                               onClick={()=>showConfirmationAlert(record._id)}
                           ></i>
                         </Link>
                    )}

                        
                        
                    
                </td>
            ),
        },
    ];
    const MySwal = withReactContent(Swal);
    const showConfirmationAlert = (id) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            showCancelButton: true,
            confirmButtonColor: '#00ff00',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonColor: '#ff0000',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteHoliday({id});
                handleReloadData();
                MySwal.fire({
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                className: "btn btn-success",
                confirmButtonText: 'OK',
                customClass: {
                confirmButton: 'btn btn-success',
                    },
                });
            } else {
                MySwal.close();
            }

        });
    };


    const handleEditClick = (id) => {
        setSelectedId(id);     
    };

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Holiday</h4>
                                <h6>Manage your holiday</h6>
                            </div>
                        </div>

                        <div className="page-btn">
                        {hasJobRightWithAdd && (
                                <Link
                                to=""
                                className="btn btn-added"
                                data-bs-toggle="modal"
                                data-bs-target="#add-department"
                            >
                                <PlusCircle className="me-2" />
                                Add New Holiday
                            </Link>
                            )}

                        </div>
                    </div>
                    {/* /product list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="search"
                                            placeholder="Search"
                                            className="form-control"
                                        />
                                        <Link to className="btn btn-searchset">
                                            <i data-feather="search" className="feather-search" />
                                        </Link>
                                    </div>
                                    <div className='wordset'>
                                    <ul>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="pdf">
                                               <img src="assets/img/icons/pdf.svg" alt="pdf" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="excel">
                                               <img src="assets/img/icons/excel.svg" alt="excel" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="print">
                                                <img src="assets/img/icons/printer.svg" alt="print" />
                                            </a>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="search-path d-flex align-items-center search-path-new">
                              
                                    <div className={`layout-hide-box`}>
                                       
                                    </div>

                                </div>
                                <div className="search-set">
                                <a href="#" className="btn btn-filter" id="filter_search">
                                    <Sliders className="me-2" />Filter
                                </a>
                            </div>
                            </div>

                            <div className="table-responsive">
                            <Table columns={columns} dataSource={dataSource} pagination={false} />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <AddHolidays onHolidayAdded={handleReloadData}/>
            <EditHolidays onHolidayAdded={handleReloadData} holidayId={selectedId}/>
        </div>
    )
}

export default Holidays


