


import { ChevronUp, PlusCircle, RotateCcw, User } from 'feather-icons-react/build/IconComponents';
import React, { useEffect, useState,useContext } from 'react';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'; 
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setToogleHeader } from '../../../core/redux/action';
import SettingsSidebar from '../settingssidebar';
import { CloseOutlined } from '@ant-design/icons';
import { getUserById, updateUser } from '../../../service/userService';
import { Spin } from "antd";
import Swal from 'sweetalert2';
import { UserContext } from "../../../context/userContext"
const GeneralSettings = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.toggle_header);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState('');
    //const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState('');
    const [province, setProvince] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false); 
    const [userDetail, setUserDetail] = useState(null);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(false);
    const { setUser } = useContext(UserContext);

    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );
    
    const renderCollapseTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Collapse
        </Tooltip>
    );

    const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    const userObj = userData.data;

    useEffect(() => {
        console.log("Use Effect 01")
        if (userObj && userObj._id) {
            setUserId(userObj._id); 

            const fetchUserData = async () => {
                try {
                    const data = await getUserById(userObj._id);
                    setUserDetail(data); 
                } catch (err) {
                    console.log(err);
                }
            };   
            fetchUserData();
        }
    }, []);

    useEffect(() => {
        if (userDetail) {
            setFirstName(userDetail.first_name);
            setLastName(userDetail.last_name);
            setEmail(userDetail.email);
           // setUserName(userDetail.user_name);
            setPhoneNumber(userDetail.phone);
            setAddress(userDetail.address);
            setCountry(userDetail.country);
            setProvince(userDetail.province);
            setCity(userDetail.city);
            setPostalCode(userDetail.postal_code);
        }
    }, [userDetail]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result); 
            };
            reader.readAsDataURL(file); 
        }
    };

    const handleImageClick = () => {
        setShowModal(true); 
    };

    const handleCloseModal = () => {
        setShowModal(false); 
    };
    //console.log(user)

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true) 
      
        const userData = {
            
            first_name:firstName,
            last_name:lastName,
            phone:phoneNumber,
            address:address,
            email:email,
            country:country,
            province:province,
            city: city,
            postalCode:postalCode




        };

        try {
            await updateUser(userId, userData);
            setTimeout(() => {
                setUser({
                    firstName: firstName,
                    lastName: lastName,
                });
            }, 500);
        } catch (error) {
            console.error('Error updating user:', error);
        }
        setLoading(false)
        
    };
    const fetchUserData = async () => {
        try {
            const data = await getUserById(userObj._id);
            setUserDetail(data);
            // Set form fields with user data
            setFirstName(data.first_name);
            setLastName(data.last_name);
            setEmail(data.email);
           // setUserName(data.user_name);
            setPhoneNumber(data.phone);
            setAddress(data.address);
            setCountry(data.country);
            setProvince(data.province);
            setCity(data.city);
            setPostalCode(data.postal_code);
        } catch (err) {
            console.log(err);
        }
    };
    const handleCancel = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will lose any unsaved changes!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, discard changes',
            cancelButtonText: 'No, keep editing'
        }).then((result) => {
            if (result.isConfirmed) {
                fetchUserData(); // Reset form fields if confirmed
                Swal.fire(
                    'Changes Discarded!',
                    'Your unsaved changes have been discarded.',
                    'success'
                );
            }
        });
    };
   // console.log(user,"USER Conteext")


    return (
        <div>
            <div className="page-wrapper">
                <div className="content settings-content">
                    <div className="page-header settings-pg-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Settings</h4>
                                <h6>Manage your settings on portal</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                                    <Link
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        id="collapse-header"
                                        className={data ? "active" : ""}
                                        onClick={() => { dispatch(setToogleHeader(!data)) }}
                                    >
                                        <ChevronUp />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="settings-wrapper d-flex">
                                <SettingsSidebar />
                                <div className="settings-page-wrap">
                                    <form>
                                        
                                               <div
                                            className="setting-title"
                                        >
                                            <h4>Profile Settings</h4>
                                            
                                        </div>
                                        <div className="card-title-head">
                                            <h6>
                                                <span>
                                                    <User className="feather-chevron-up" />
                                                </span>
                                                User Information
                                            </h6>
                                        </div>
                                        <div className="profile-pic-upload">
                                            <div className="profile-pic">
                                                {selectedImage ? (
                                                    <img
                                                        src={selectedImage}
                                                        alt="Profile"
                                                        style={{
                                                            width: "150px",
                                                            height: "118px",
                                                            borderRadius: "10%",
                                                            objectFit: "cover",
                                                        }}
                                                        onClick={handleImageClick} 
                                                    />
                                                ) : (
                                                    <span>
                                                        <PlusCircle className="plus-down-add" />
                                                        Profile Photo
                                                    </span>
                                                )}
                                            </div>
                                            <div className="new-employee-field">
                                                <div className="mb-0">
                                                    <div className="image-upload mb-0">
                                                        <input type="file" onChange={handleImageChange} />
                                                        <div className="image-uploads">
                                                            <h4>Change Image</h4>
                                                        </div>
                                                    </div>
                                                    <span>
                                                        For better preview recommended size is 450px x 450px. Max
                                                        size 5MB.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">First Name</label>
                                                    <input
                                                        type="text"
                                                        id='firstName'
                                                        className="form-control"
                                                        value={firstName}
                                                        readOnly={false}
                                                        onChange={(event) => setFirstName(event.target.value)}
                                                        // onChange={(event) => {
                                                        //     console.log("Typing in First Name:", event.target.value); // Debugging line
                                                        //     setFirstName(event.target.value);
                                                        // }}
                                                        disabled={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Last Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={lastName}
                                                        onChange={(event) => setLastName(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">User Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={userName}
                                                        disabled
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Phone Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={phoneNumber}
                                                        onChange={(event) => setPhoneNumber(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        value={email}
                                                        onChange={(event) => setEmail(event.target.value)}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-title-head">
                                            <h6>Residence Info</h6>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={address}
                                                        onChange={(event) => setAddress(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-md-3">
                                                <div className="mb-3">
                                                    <label className="form-label">Country</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={country}
                                                        onChange={(event) => setCountry(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-md-3">
                                                <div className="mb-3">
                                                    <label className="form-label">State / Province</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={province}
                                                        onChange={(event) => setProvince(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-md-3">
                                                <div className="mb-3">
                                                    <label className="form-label">City</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={city}
                                                        onChange={(event) => setCity(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-md-3">
                                                <div className="mb-3">
                                                    <label className="form-label">Postal Code</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={postalCode}
                                                        onChange={(event) => setPostalCode(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-0 d-flex justify-content-end">
                                            <button type="submit" className="btn btn-cancel me-2"
                                            onClick={handleCancel} >
                                                cancel
                                            </button>
                                            <button type="submit" className="btn btn-submit"
                                            onClick={handleSubmit}>
                                                {loading ? <Spin size="small" /> : "Save Changes"}
                                            </button>
                                                
                                        </div>
                              
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <Modal show={showModal} onHide={handleCloseModal} size="md" centered>
    <div style={{ position: 'relative' }}>
        <CloseOutlined
            onClick={handleCloseModal}
            style={{
                position: 'absolute',
                top: 10,   
                right: 10,
                fontSize: '16px',
                cursor: 'pointer',
                zIndex: 1 ,
                color:"red" 
            }}
        />
        <Modal.Body style={{ padding: 0, margin:0 }}>
            {selectedImage && (
                <img
                    src={selectedImage}
                    alt="Full Size Preview"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}  
                />
            )}
        </Modal.Body>
    </div>
</Modal>

        </div>
    );
};

export default GeneralSettings;




