import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../core/breadcrumbs";
import { Link } from "react-router-dom";
import { Filter, Sliders, Edit, Trash2 } from "react-feather";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import Select from "react-select";
import { DatePicker, Table } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ExpenseModal from "../../core/modals/FinanceAccount/expenseModal";
import { Calendar, Eye, FileText, StopCircle, User } from "feather-icons-react/build/IconComponents";
import { BASE_URL } from "../../environment";
import { API_BASE_URL } from "../../../src/environment";

const ExpensesList = () => {
    // const route = all_routes;
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateTwo, setSelectedDateTwo] = useState(null);
    const [selectedExpenseId, setSelectedExpenseId] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handlerefChange = (date) => {
        setSelectedDateTwo(date);
    };
    const options = [
        { value: "sortByDate", label: "Sort by Date" },
        { value: "140923", label: "14 09 23" },
        { value: "110923", label: "11 09 23" },
    ];
    const optionsOne = [
        { value: "chooseName", label: "Choose Name" },
        { value: "macbookPro", label: "Macbook Pro" },
        { value: "orange", label: "Orange" },
    ];

    const optionsTwo = [
        { value: "chooseStatus", label: "Choose Status" },
        { value: "computers", label: "Computers" },
        { value: "fruits", label: "Fruits" },
    ];


    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
    };

  const [expenses, setExpenses] = useState([]);

  const [shouldReloadData, setShouldReloadData] = useState(false);



  const storedUserData = localStorage.getItem("user");
  const userData = JSON.parse(storedUserData);
  const id = userData?.data?.uid; //
  const [userRightssub, setUserRightssub] = useState([]);
  useEffect(() => {
    const fetchUserRightssub = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/userrightsub/sub/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user rights");
        }
        const data = await response.json();
        console.log("Fetched User Sub Data:", data.data);
        setUserRightssub(data.data || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching data:", error);
        setUserRightssub([]); // Set to empty array in case of error
      }
    };
  
    fetchUserRightssub(); // Fetch the data on component mount
  }, [id]);
  const hasJobRightWithAdd = userRightssub.some(right => right.right === 'expense-list' && right.adds === 1);
  const hasJobRightWithedit = userRightssub.some(right => right.right === 'expense-list' && right.updates === 1);
  const hasJobRightWithview = userRightssub.some(right => right.right === 'expense-list' && right.viewes === 1);  
  const hasJobRightWithedel = userRightssub.some(right => right.right === 'expense-list' && right.deletes=== 1); 
    







  useEffect(() => {
    fetchExpense();
  }, [shouldReloadData]);

  const fetchExpense = async () => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/expenses`);
      if (!response.ok) {
        throw new Error("Failed to fetch Expense");
      }
      const data = await response.json();
      console.log("Fetched data:", data);
      setExpenses(data.data.expense);
    } catch (error) {
      console.error("Error fetching expense:", error);
    }
  };

  const handleReloadData = () => {
    setShouldReloadData(!shouldReloadData);
  };


  const columns = [
    {
      render: () => (
        <label className="checkboxs">
          <input type="checkbox" />
          <span className="checkmarks" />
        </label>
      ),
    },

    {
      title: "Category Name",
      dataIndex: "CategoryName",
      // sorter: (a, b) => a.categoryName.length - b.categoryName.length,
    },

    {
        title: "Reference",
        dataIndex: "Reference",
        // sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
    {
      title: "Date",
      dataIndex: "Date",

    },
    
    {
      title: "Status",
      dataIndex: "Status",

    },
    
    {
      title: "Amount",
      dataIndex: "Amount",

    },

    {
      title: "Description",
      dataIndex: "Description",
    },

    {
       title: "Action",
       dataIndex: "action",
    },
  ];

  const MySwal = withReactContent(Swal);

  
  const showConfirmationAlert = (expenseId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteExpense(expenseId);
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  const deleteExpense = async (expenseId) => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/expenses/${expenseId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        handleReloadData();
        console.log("expense deleted successfully");
      } else {
        console.error("Failed to delete expense");
      }
    } catch (error) {
      console.error("Error deleting expense:", error);
    }
  };
  
  const handleLinkClick = async (expenseId) => {
    setSelectedExpenseId(expenseId);
  };

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredExpences = expenses.filter(expense => {
    const searchLower = searchQuery.toLowerCase();
    return (
      expense.expenseCategory.expenseCategoryName.toLowerCase().includes(searchLower)
    );
});

  return (
    <div className="page-wrapper">
      <div className="content">
      {hasJobRightWithAdd && (
          <Breadcrumbs
            maintitle="Supplier List "
            subtitle="Manage Your Supplier"
            addButton="Add New Supplier"
          />
      )}

        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="search" 
                    className="form-control form-control-sm formsearch" 
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              <div className="search-path">
                <Link
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div>
              <div className="form-sort stylewidth">
                <Sliders className="info-img" />

                <Select
                  className="select "
                  options={options}
                  placeholder="Sort by Date"
                />
              </div>
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <User className="info-img" />
                        <Select
                          className="select select-height"
                          options={optionsOne}
                          defaultValue={optionsOne[0]}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />
                        <Select
                          className="select select-height"
                          options={optionsTwo}
                          defaultValue={optionsTwo[0]}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Calendar className="info-img" />
                        <div className="input-groupicon">
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="From Date - To Date"
                            className="datetimepicker"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        {/* <i data-feather="file-text" className="info-img" /> */}
                        <FileText className="info-img" />
                        <div className="input-groupicon">
                          {/* <input
                            type="text"
                            className="datetimepicker"
                            placeholder="Enter Reference"
                          /> */}
                          <DatePicker
                            selected={selectedDateTwo}
                            onChange={handlerefChange}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Enter Reference"
                            className="datetimepicker"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
            <div className="table-responsive">
            <Table
                  className="table datanew"
                  columns={columns}
                  dataSource={filteredExpences.map((expense) => ({
                    key: expense._id,
                    CategoryName: expense.expenseCategory.expenseCategoryName,
                    Reference: expense.expenseReference,
                    Date: expense.expenseDate,
                    Status: (
                        <span className={`badge ${expense.expenseState ? 'badge-linesuccess' : 'badge-linedanger'}`}>
                          {expense.expenseState ? 'Active' : 'Inactive'}
                        </span>
                      ),
                      Amount: `$${expense.expenseAmount.toFixed(2)}`,
                    Description: expense.expenseDescription,
                    action: (
                      <td className="action-table-data">
                        <div className="edit-delete-action">
                          <div className="input-block add-lists"></div>
                          {hasJobRightWithview && (
                            <Link className="me-2 p-2" to="#">
                              <Eye className="feather-view" />
                            </Link>
                          )}
                          {hasJobRightWithedit && (
                              <Link
                              className="me-2 p-2"
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#edit-units"
                              onClick={() => handleLinkClick(expense._id)}
                              >
                              <Edit className="feather-edit" />
                              </Link>
                          )}
                          {hasJobRightWithedel && (
                              <Link
                              className="confirm-text p-2"
                              to="#"
                              onClick={() => showConfirmationAlert(expense._id)}
                              >
                              <Trash2 className="feather-trash-2" />
                              </Link>
                          )}
                        </div>
                      </td>
                    ),
                  }))}
                  pagination={false}
                />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>

      <ExpenseModal onExpenseAdded={handleReloadData} expenseId={selectedExpenseId}/>
    </div>
  );
};

export default ExpensesList;
