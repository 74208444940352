import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { ChevronUp, RotateCcw } from "feather-icons-react/build/IconComponents";
import { setToogleHeader } from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import {
  Filter,
  PlusCircle,
  Sliders,
  StopCircle,
  User,
  Zap,
} from "react-feather";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import AddUsers from "../../core/modals/usermanagement/addusers";
import EditUser from "../../core/modals/usermanagement/edituser";
import { deleteUser } from '../../service/ApiServices';
import { API_BASE_URL } from "../../../src/environment";

const Users = () => {

  const [userData, setUserData] = useState([]);
  const [updateUser, setUpdateUser] = useState(''); //set the update user
  let currentDate = new Date();  //Not work in UI

  // console.log(userData, "USER DATA ==== user.jsx")

  const handleUpdateUser = (record) => {
    setUpdateUser(record);
  };

  //delete user

  const deleteUserData = async (record) => {
    try {
      // console.log(record.key,"RECORD user.jsx")
      await deleteUser(record.key); 
      const updatedUserData = userData.filter((user) => user !== record);
      // showConfirmationAlert(record); 
      setUserData(updatedUserData);
     
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const storedUserData = localStorage.getItem("user");
  const userData1 = JSON.parse(storedUserData);
  const id = userData1?.data?.uid; //
  const [userRightssub, setUserRightssub] = useState([]);
  useEffect(() => {
    const fetchUserRightssub = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/userright/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user rights");
        }
        const data = await response.json();
        console.log("Fetched User Sub Data:", data.data);
        setUserRightssub(data.data || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching data:", error);
        setUserRightssub([]); // Set to empty array in case of error
      }
    };
  
    fetchUserRightssub(); // Fetch the data on component mount
  }, [id]);
  const hasJobRightWithAdd = userRightssub.some(right => right.right === 'Users' && right.adds === 1);
  const hasJobRightWithedit = userRightssub.some(right => right.right === 'Users' && right.updates === 1);
  const hasJobRightWithview = userRightssub.some(right => right.right === 'Users' && right.viewes === 1);  
  const hasJobRightWithedel = userRightssub.some(right => right.right === 'Users' && right.deletes=== 1); 
 // const hasJobRightWithdown = userRightssub.some(right => right.right === 'Shifts' && right.fdownload=== 1); 


  useEffect(() => {
    // Fetch user data from your backend API
    fetch("http://localhost:8000/api/user/allusers")
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((data) => {

        if (!Array.isArray(data.data)) {
          throw new Error('Data is not an array');
        }

        const transformedData = data.data.map((user) => ({
          key: user._id,   //roleId change
          name: user.name,   //name->username
          email: user.email,
          role: user.role ? user.role.name : "No data",
          phone: user.phone || "No data",
          createdon: currentDate || "No data",
          status: user.status || "No data",
          password: user.password,
          confirmPassword: user.password
        }));


        setUserData(transformedData);

      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, []);




  const oldandlatestvalue = [
    { value: "date", label: "Sort by Date" },
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
  ];

  const status = [
    { value: "Choose Name", label: "Choose Status" },
    { value: "Active", label: "Active" },
    { value: "InActive", label: "InActive" },
  ];
  const role = [
    { value: "Choose Role", label: "Choose Role" },
    { value: "AcStore Keeper", label: "Store Keeper" },
    { value: "Salesman", label: "Salesman" },
  ];

  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  //const dataSource = useSelector((state) => state.userlist_data);
  //console.log(dataSource,"DATA SOURCE")
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const columns = [
    {
      title: "User Name",
      dataIndex: "name",
      render: (text, record) => (
        <span className="userimgname">
          <Link to="#" className="userslist-img bg-img">
            <ImageWithBasePath alt="" src={record.img} />
          </Link>
          <div>
            <Link to="#">{text}</Link>
          </div>
          {/* {console.log(record,"record user.jsx")} */}
        </span>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: (a, b) => a.role.length - b.role.length,
    },
    {
      title: "Created On",
      dataIndex: "createdon",
      sorter: (a, b) => a.createdon.length - b.createdon.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <div>
          {text === "Active" && (
            <span className="badge badge-linesuccess">{text}</span>
          )}
          {text === "Inactive" && (
            <span className="badge badge-linedanger">{text}</span>
          )}
        </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
              {hasJobRightWithview && (
                     <Link className="me-2 p-2" to="#">
                     <i
                       data-feather="eye"
                       className="feather feather-eye action-eye"
                     ></i>
                     {/* {console.log(record, "record user.jsx")} */}
                   </Link>
                )}
                {hasJobRightWithedit && (
                     <Link
                     className="me-2 p-2"
                     to="#"
                     data-bs-toggle="modal"
                     data-bs-target="#edit-units"
                     onClick={() => handleUpdateUser(record)}
                   //onClick={() => handleEditClick(record)}
                   >
                     {/* {console.log(handleUpdateUser, "handleupdate user.jsx")} */}
                     <i data-feather="edit" className="feather-edit"></i>
                   </Link>
                )}
                {hasJobRightWithedel && (
                     <Link className="confirm-text p-2" to="#">
                     <i
                       data-feather="trash-2"
                       className="feather-trash-2"
                       onClick={() => showConfirmationAlert(record)}
                     ></i>
                   </Link>
                )}
          </div>
        </td>
      ),
    },
  ];
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (record) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        deleteUserData(record);
      } else {
        MySwal.close();
      }
    });
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>User List</h4>
                <h6>Manage Your Users</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
            {hasJobRightWithAdd && (
                <a
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-units"
              >
                <PlusCircle className="me-2" />
                Add New User
              </a>
            )}
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="search-path">
                  <Link
                    className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                      }`}
                    id="filter_search"
                  >
                    <Filter
                      className="filter-icon"
                      onClick={toggleFilterVisibility}
                    />
                    <span onClick={toggleFilterVisibility}>
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div>
                <div className="form-sort">
                  <Sliders className="info-img" />
                  <Select
                    className="select"
                    options={oldandlatestvalue}
                    placeholder="Newest"
                  />
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <User className="info-img" />

                        <Select
                          className="select"
                          options={userData}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={status}
                          placeholder="Choose Status"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Zap className="info-img" />

                        <Select
                          className="select"
                          options={role}
                          placeholder="Choose Role"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <a className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table columns={columns} dataSource={userData} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddUsers />
      <EditUser userRole={updateUser.role} userId={updateUser.key} user={updateUser} />
    </div>
  );
};

export default Users;
