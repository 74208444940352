import React, { useEffect, useState } from 'react';
import { Table, Modal, Popconfirm } from 'antd';
import { PlusCircle, Sliders } from 'react-feather';
import { Link } from 'react-router-dom';
import { deleteAllowanceMethod, getAllAllowances } from '../../service/ApiServices';
import CreateAllovanceModal from './createAllvance';

const Allowance = () => {
    const [data, setData] = useState([]);
    const [isAllowanceAdd, setIsAllowanceAdd] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedAllowance, setSelectedAllowance] = useState(null);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDelete, setIsDelete] = useState(false)
    const fetchData = async () => {
        setLoading(true);
        let data = await getAllAllowances({ search, setLoading });
        setData(data);
    };
    useEffect(() => {
        const handler = setTimeout(() => {
            fetchData();
            setIsAllowanceAdd(false);
            setSelectedAllowance(null);
        }, 600);
        return () => {
            clearTimeout(handler);
        };
    }, [search]);
    useEffect(() => {
        if (isAllowanceAdd || isDelete) {
            fetchData()
            setIsAllowanceAdd(false);
        }
    }, [isAllowanceAdd,isDelete])
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setSelectedAllowance(null);
        setIsModalOpen(false);
    };

    const handleAllowanceAdding = () => {
        setIsAllowanceAdd(true);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <td className="action-table-data">
                    <Link
                        className="me-2 p-2"
                        to="#"
                        onClick={() => {
                            setSelectedAllowance(record);
                            setIsModalOpen(true);
                        }}
                    >
                        <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                    <Popconfirm
                        title="Delete the Allowance"
                        description="Are you sure to disable this Allowance?"
                        onConfirm={() => {
                            deleteAllowanceMethod({
                                id: record._id,
                                setIsDelete,
                                setLoading
                            })
                            console.log("c")
                        }}
                        onCancel={() => {
                            console.log("c")
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Link className="confirm-text p-2" >
                            <i data-feather="trash" className="feather-trash"></i>
                        </Link>
                    </Popconfirm>

                </td>
            ),
        },
    ];


    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Allowance</h4>
                            <h6>Manage your allowances</h6>
                        </div>
                    </div>
                    <div className="page-btn">
                        <a className="btn btn-added" onClick={() => setIsModalOpen(true)}>
                            <PlusCircle className="me-2" />
                            Add New Allowance
                        </a>
                    </div>
                </div>

                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <Link className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </Link>
                                </div>
                                <div className="wordset">
                                    <ul>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="pdf">
                                                <img src="assets/img/icons/pdf.svg" alt="pdf" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="excel">
                                                <img src="assets/img/icons/excel.svg" alt="excel" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="print">
                                                <img src="assets/img/icons/printer.svg" alt="print" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="search-set">
                                <a href="#" className="btn btn-filter" id="filter_search">
                                    <Sliders className="me-2" />Filter
                                </a>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table loading={loading} columns={columns} dataSource={data} rowKey="value" pagination={false} />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                zIndex={200000}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                closable={false}
            >
                <CreateAllovanceModal handleAllowanceAdding={handleAllowanceAdding} handleCancel={handleCancel} selectedAllowance={selectedAllowance} />
            </Modal>
        </div>
    );
};

export default Allowance;
