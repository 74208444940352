import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import './style.css';
import Swal from 'sweetalert2';
import { Spin } from 'antd';
import { createAllwanceMethod } from '../../service/ApiServices';
const CreateAllovanceModal = ({ handleCancel, handleAllowanceAdding, selectedAllowance }) => {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSave = () => {
        if (!name) {
            showWarning("Name is required!")
            return
        }
        createAllwanceMethod({
            handleAllowanceAdding,
            handleCancel,
            handleReset,
            name,
            setLoading,
            showSuccess,
            id: selectedAllowance ? selectedAllowance._id : null
        })
    };
    const handleReset = () => {
        setName("")
    };
    const showSuccess = () => {
        return Swal.fire({
            title: "Good job!",
            text: "You Successfully Saved Allowance!",
            icon: "success"
        });
    }

    const showWarning = (str) => {
        return Swal.fire({
            title: "Error",
            text: str,
            icon: "error",
        });
    }

    useEffect(() => {
        if (selectedAllowance) {
            setName(selectedAllowance.name);
        }
    }, [selectedAllowance]);
    return (
        <div className="modal-content">
            <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                    <h4>{selectedAllowance ? "Edit Allowances" : "Create Allowances"}</h4>
                    <p className="mb-0">Fill in the required fields to create a allowances</p>
                </div>
            </div>
            <div className="modal-body custom-modal-body">
                <form>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="input-blocks">
                                <label htmlFor="name">Allowance Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    className="form-control"
                                    aria-label="Job Type Name"
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }} >
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={() => {
                            handleCancel();
                            handleReset();
                        }}
                    >
                        Close
                    </button>
                    <div>
                        <button
                            type="button"
                            className="btn btn-reset me-2"
                            onClick={handleReset}
                        >
                            Reset
                        </button>
                        <button className="btn btn-submit" onClick={handleSave}>
                            {loading ? <Spin size='small' /> : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
CreateAllovanceModal.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    handleAllowanceAdding: PropTypes.func.isRequired,
    selectedAllowance: PropTypes.object.isRequired
};
export default CreateAllovanceModal;
