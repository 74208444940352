
import { DatePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import { createHoliday } from '../../../service/holidayService';
import propTypes from 'prop-types';
import Swal from 'sweetalert2';

const AddHolidays = ({ onHolidayAdded }) => {
    const [name, setName] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus] = useState(true);
    const [duration, setDuration] = useState(0);
    // const [error, setError] = useState('');

    // setDuration
    useEffect(() => {
        if (startDate && endDate) {
            if (endDate.isBefore(startDate, 'day')) {
                showWarning('End date cannot be earlier than start date.');
                setEndDate(null);
                setDuration(0);
            } else {
                const diff = endDate.diff(startDate, 'days') + 1;
                setDuration(diff);
                //setError(''); 
            }
        }
    }, [startDate, endDate]);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleStatusChange = (e) => {
        setStatus(e.target.checked);
    };

    const handleSubmit = async () => {
        if (!name || !startDate || !endDate) {
            console.error('Please fill in all fields.');
            //setError('Please fill all the fields.'); 
            return;
        }

        const holiday = {
            name,
            startDate,
            endDate,
            status,
            duration
        };

        try {
            const response = await createHoliday(holiday);
            console.log('Holiday added successfully', response.data);
            onHolidayAdded(holiday);
            handleReset();

        } catch (error) {
            console.error('Error adding holiday:', error);
        }
    };

    const handleReset = () => {
        setName('');
        setStartDate(null);
        setEndDate(null);
        setStatus(true);
        setDuration(0);
        //setError('');
    };
    const showWarning = (str) =>{
        return Swal.fire({
          title: "Error",
          text: str,
          icon: "error",
        });
      }
    return (
        <div>
            {/* Add Department */}
            <div className="modal fade" id="add-department">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Add Holiday</h4>
                                    </div>
                                    {/* <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button> */}
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label>Add Holiday</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Start Date</label>
                                                    <div className="input-groupicon calender-input">
                                                        <DatePicker
                                                            value={startDate}
                                                            onChange={handleStartDateChange}
                                                            type="date"
                                                            className="filterdatepicker"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholder='20-2-2024'
                                                            getPopupContainer={(trigger) => trigger.parentNode}
                                                            style={{ width: "100%",
                                                                boxSizing:"border-box"
                                                             }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>End Date</label>
                                                    <div className="input-groupicon calender-input">
                                                        <DatePicker
                                                            value={endDate}
                                                            onChange={handleEndDateChange}
                                                            type="date"
                                                            className="filterdatepicker"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholder='20-2-2024'
                                                            getPopupContainer={(trigger) => trigger.parentNode}
                                                            style={{ width: "100%",
                                                                boxSizing:"border-box"
                                                             }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="input-blocks">
                                                    <label>No of Days</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="01"
                                                        value={duration}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                      
                                            <div className="input-blocks m-0 pb-1">
                                                <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                    <span className="status-label">Status</span>
                                                    <input
                                                        type="checkbox"
                                                        id="user5"
                                                        className="check"
                                                        checked={status}
                                                        onChange={handleStatusChange}
                                                    />
                                                    <label htmlFor="user5" className="checktoggle">
                                                        {" "}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                <div style={{
                                         display: "flex",
                                         justifyContent: "space-between",
                                        alignItems: "center",
                                        
                                     }} className="mt-4" >
                                    <button
                                        type="button"
                                        className="btn btn-cancel"
                                        data-bs-dismiss="modal"
                                        >
                                              Close
                                    </button>
                                <div>
                                    <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                onClick={handleReset}
                                            >
                                                Reset
                                    </button>
                                    <button
                                                type="button"
                                                className="btn btn-submit"
                                                onClick={handleSubmit}
                                                data-bs-dismiss="modal"
                                            >
                                                Save
                                    </button>
                                </div>
                                </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Department */}
        </div>
    );
};

AddHolidays.propTypes = {
    onHolidayAdded: propTypes.func.isRequired
};

export default AddHolidays;
