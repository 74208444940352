import axios from "axios";

// Add leave type
export const addLeaveTypes = async ({
  name,
  quota,
  minimumLeaveCount,
  handleCancel,
  handleReset,
  showSuccess,
  Id,
  handleLeaveTypeAdding,
  setLoading,
}) => {
  try {
    // const userId = JSON.parse(localStorage.getItem('user')).data._id;
    setLoading(true);
    await axios
      .post("/leave-types", {
        name,
        quota,
        minimumLeaveCount,
        leaveTypeId: Id,
        // userId
      })
      .then((response) => {
        handleCancel();
        handleReset();
        setLoading(false);
        showSuccess();
        handleLeaveTypeAdding();
        console.log(response);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  } catch (error) {
    console.log(error.message);
  }
};

//Get all leave types
export const getAllLeaveTypes = async ({ search, setLoading }) => {
  try {
    const response = await axios.get(
      "/leave-types?user=" +
        JSON.parse(localStorage.getItem("user")).data._id +
        "&search=" +
        search
    );
    setLoading(false);
    // console.log(response);
    return response.data.data.leavetypes;
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};

// Delete single leave type
export const deleteLeaveType = async (id) => {
  try {
    const response = await axios.delete(`/leave-types/${id}`);
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};
// leaveTypesApi.js


// Function to get a leave type by ID using axios
export async function getLeaveTypeById(id) {
  try {
    const response = await axios.get(`/leave-types/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch leave type:', error);
  }
}

// Example usage
getLeaveTypeById('some-id').then(data => console.log(data));
