import React, { useEffect, useState } from "react";
import { createLeaveEmployee } from "../../../service/leavesEmployeeApiService";
import { getAllLeaveTypes } from "../../../service/leaveApiServices/leaveTypesApi";
//import TextEditor from "../../../feature-module/inventory/texteditor";
//import ReactQuill from "react-quill";
//import { fetchLeaveTypeAndCount } from "../../../service/leavesEmployeeApiService";

import Select from "react-select";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const AddLeaveEmployee = ({ handleCancel }) => {
  const leaveDayOptions = [
    { value: "Choose Status", label: "Choose Status" },
    { value: "Full Day", label: "Full Day", dayValue: 1 },
    { value: "Half Day", label: "Half Day", dayValue: 0.5 },
  ];

  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [dateRange, setDateRange] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const [reason, setReason] = useState();
  const [leaveDays, setLeaveDays] = useState([]);
  const [leaveType, setLeaveType] = useState([]); //[funeral, sick leave]
  //  const [minLeaveCount, setMinLeaveCount] = useState(null);

  // Handle Date Change
  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  useEffect(() => {
    fetchLeaveTypes();
  }, []);

  const fetchLeaveTypes = async () => {
    try {
      const leaveTypeData = await getAllLeaveTypes({
        search: "",
        setLoading: () => {}, // Optional loading state
      });

    

      if (leaveTypeData) {
        const options = leaveTypeData.map((leave) => ({
          id: leave._id,
          label: leave.name,
          leaveCount: leave.minimumLeaveCount,
        }));

        setLeaveType([{ value: "Choose", label: "Choose" }, ...options]);
      }
    } catch (error) {
      console.error("Error fetching leave Types:", error);
    }
  };

  // Handle Date Change
  const handleDateChange = (dates) => {
    setSelectedDate(dates);
    if (dates && dates[0] && dates[1]) {
      const start = new Date(dates[0]);
      const end = new Date(dates[1]);

      const duration = Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;
      // Check if the selected date range is less than the minimum leave count
      if (selectedLeaveType) {
        // Get the leave count (minimum leave days) from the selected leave type
        const leaveCount = selectedLeaveType.leaveCount;

        // If the selected date range is less than the leave count, show an alert
        if (duration < leaveCount) {
          alert(`The selected date range must be at least ${leaveCount} days.`);
          return; // Prevent further processing if the duration is too short
        }
      }

      // Proceed to set the date range and leave days
      const daysArray = [];
      let dayCount = 1;
      let currentDate = new Date(start);
      while (currentDate.getTime() <= end.getTime()) {
        daysArray.push({
          day: `Day ${dayCount}`,
          date: formatDate(currentDate),
          leaveType: null,
        });
        currentDate.setDate(currentDate.getDate() + 1);
        dayCount++;
      }
      setDateRange(daysArray);
      setLeaveDays(daysArray);
    } else {
      setDateRange([]);
      setLeaveDays([]);
    }
  };

  // Handle Leave Type Change
  const handleLeaveTypeChange = (index, selectedOption) => {
    
    const updatedLeaveDays = [...leaveDays];
    updatedLeaveDays[index].leaveType = selectedOption.label;
    updatedLeaveDays[index].dayValue = selectedOption.dayValue;
    setLeaveDays(updatedLeaveDays);
  };

  const handleLeaveTypeSelection = (selectedOption) => {
    setSelectedLeaveType(selectedOption); 
    console.log("Selected Leave Type:", selectedOption); 
  };

  // Calculate total leave days (sum of Full Day and Half Day values)
  const calculateTotalLeaveDays = () => {
    return leaveDays.reduce((total, day) => total + (day.dayValue || 0), 0); // Default to 0 if dayValue is not set
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("userId:", user.data._id);
    console.log("name:", user.data.name);

    if (!user || !user.data._id || !user.data.name) {
      console.error("User data is missing from local storage.");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "User data is missing from local storage. Please log in again.",
      });
      return;
    }

    const totalLeaveDays = calculateTotalLeaveDays();
    if (selectedLeaveType && totalLeaveDays < selectedLeaveType.leaveCount) {
      alert(
        `The total leave days must be at least ${selectedLeaveType.leaveCount}. Currently selected: ${totalLeaveDays}`
      );
      return;
    }
    if (!reason || reason.trim() === "") {
      alert("Reason is required.");
      return;
    }
    // Prepare the leave data
    const leaveData = {
      leavetype: selectedLeaveType.id,
      leaveTypeName: selectedLeaveType.label,
      leaveDays: leaveDays.map((day) => ({
        date: day.date,
        Day: day.leaveType, // Full Day or Half Day
      })),
      reason: reason,
      startDate: selectedDate[0].format("YYYY-MM-DD"),
      endDate: selectedDate[1].format("YYYY-MM-DD"),
      approval: false, // Set default approval
      // Add userId and name from local storage
      userId: user.data._id,
      name: user.data.name,
    };

    try {
      const response = await createLeaveEmployee(leaveData);
      console.log("Response from server:", response);

      // Check if the server responded with a success status
      if (response.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Leave Submitted",
          text: "Leave has been submitted successfully!",
        }).then(() => {
          handleCancel(); // Close the modal or reset the form
        });
      } else {
        // Handle non-success statuses
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: response.message || "Failed to submit leave. Please try again.",
        });
      }
    } catch (error) {
      console.error("Failed to submit leave:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to submit leave. Please try again.",
      });
    }
  };

  return (
    <div>
      {/* Add Leave */}

      <div className="page-wrapper-new p-0">
        <div className="content">
          <div className="modal-header border-0 custom-modal-header">
            <div className="page-title">
              <h4>Apply Leave</h4>
            </div>
          </div>
          <div className="modal-body custom-modal-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-blocks">
                    <label>Date Range</label>
                    <div className="input-groupicon calender-input">
                      <RangePicker
                        value={selectedDate}
                        onChange={handleDateChange}
                        className="filterdatepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholder={["Start Date", "End Date"]}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                      />
                    </div>
                  </div>
                </div>

                {dateRange.map((item, index) => (
                  <div key={index} className="col-lg-12">
                    <div className="apply-leave">
                      <div className="leave-apply">
                        <div className="leave-date">
                          <span>{item.day}</span>
                          <p>{item.date}</p>
                        </div>
                        <div className="leave-time">
                          <div className="input-blocks mb-0">
                            <Select
                              className="select"
                              options={leaveDayOptions}
                              placeholder="Select Leave Day"
                              onChange={(selectedOption) =>
                                handleLeaveTypeChange(index, selectedOption)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label">Select Leave Type</label>

                    <Select
                      className="select"
                      options={leaveType}
                      placeholder="Select Leave Day"
                      onChange={handleLeaveTypeSelection}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mb-3 summer-description-box mb-0">
                    <label>Reason</label>

                    <input
                      type="text"
                      className="form-control"
                      onChange={(event) => setReason(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer-btn">
                <button
                  type="button"
                  className="btn btn-cancel me-2"
                  onClick={handleCancel}
                >
                  Cancel
                </button>

                <button className="btn btn-submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* /Add Leave */}
    </div>
  );
};
AddLeaveEmployee.propTypes = {
  handleCancel: PropTypes.func.isRequired,
};
export default AddLeaveEmployee;
